export async function fetchWrapper<T>(
  url: string,
  options?: RequestInit,
  data?: Record<string, unknown>
): Promise<T> {
  let headers: HeadersInit = {};
  if (options?.method === "POST")
    headers = {
      "Content-Type": "application/json",
    };

  const response = await fetch(
    `https://gw-damagereportform-pool.enable.jarowa.ch/DamageReportForm/api/v1/${url}`,
    {
      // mode: 'cors',
      // cache: "no-cache",
      headers,
      body: JSON.stringify(data),
      // referrerPolicy: "no-referrer",
      ...options,
    }
  );

  const responseData = (await response.json()) as T;
  // console.log(data)
  return responseData;
  // return response;
}
