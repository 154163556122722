import React from "react";

export const ElectricityIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorSecond}
        d="M37.44687,20.58088,24.51857,40.55142a1.30262,1.30262,0,0,0,1.09349,2.01051h8.02146L23.5817,61.1141a1.41917,1.41917,0,0,0,2.18662,1.74032L53.716,38.20021a1.30263,1.30263,0,0,0-.86173-2.27948H43.2636L56.30624,21.13907a1.17013,1.17013,0,0,0-.87741-1.94431H39.99541A3.03593,3.03593,0,0,0,37.44687,20.58088Z"
      />
      <path
        fill={colorFirst}
        d="M40,79.87891A39.87891,39.87891,0,1,1,79.87842,40,39.92371,39.92371,0,0,1,40,79.87891ZM40,3.12109A36.87891,36.87891,0,1,0,76.87842,40,36.92044,36.92044,0,0,0,40,3.12109Z"
      />
    </svg>
  );
};
