import classNames from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import { Container } from "../../../components/Container";
import { Input } from "../../../components/Input";
import {
  useReportDamageActionsContext,
  useReportDamageDataContext,
} from "../providers/ReportDamageProvider";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import React from "react";

const reporterRoles = ["tenant", "janitor", "others"];

export const ReportContacts = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { contactData, detailsDescription } = useReportDamageDataContext();
  const { setContactData } = useReportDamageActionsContext();

  const onDataChange = (field: keyof typeof contactData, value: string) => {
    setContactData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const isConfirmDisabled = useMemo(() => {
    if (!detailsDescription) return true;

    const requiredFields = ["fullName", "location", "role", "beltLabel"];
    const contactFields = ["phoneNumber", "email"];

    for (const [key, value] of Object.entries(contactData)) {
      if (requiredFields.includes(key) && !value) return true;
    }

    return !contactFields.find((field) => contactData[field]) ?? true;
  }, [contactData, detailsDescription]);

  return (
    <Container
      reportStep={3}
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            onClick={() => navigate(`/${accountName}/report/confirm`)}
            className="w-full lg:w-fit"
            disabled={isConfirmDisabled}
          >
            {t("confirm_contact_data")}
          </Button>
        </div>
      }
    >
      <div className="justify-center items-center relative lg:flex lg:mb-12">
        <Link
          to={`/${accountName}/report`}
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{t("details")}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl lg:m-0">
          {t("how_can_we_contact_you")}
        </div>
      </div>

      <div className="lg:grid grid-cols-[1fr_min-content_1fr] gap-10">
        <div>
          <div className="text-[#2F231C] text-2xl font-semibold mb-6 hidden lg:block">
            {t("contact_information")}
          </div>
          <div>
            <div className="mb-2 text-xs color-[#2F231C]">
              {`${t("full_name")}*:`}
            </div>
            <Input
              placeholder={t("enter_full_name") ?? ""}
              size="large"
              value={contactData.fullName}
              onChange={(event) =>
                onDataChange("fullName", String(event.target.value))
              }
            />
          </div>
          <div className="mt-5">
            <div className="mb-2 text-xs color-[#2F231C]">
              {`${t("phone_number")}*:`}
            </div>
            <Input
              placeholder={t("enter_phone_number") ?? ""}
              size="large"
              value={contactData.phoneNumber}
              onChange={(event) =>
                onDataChange("phoneNumber", String(event.target.value))
              }
            />
          </div>
          <div className="mt-5">
            <div className="mb-2 text-xs color-[#2F231C]">
              {`${t("email")}*:`}
            </div>
            <Input
              placeholder={t("enter_your_email") ?? ""}
              size="large"
              value={contactData.email}
              onChange={(event) =>
                onDataChange("email", String(event.target.value))
              }
            />
          </div>
        </div>

        <div className="border-r border-[#ECEDEB] h-full" />

        <div>
          <div className="text-[#2F231C] text-2xl font-semibold mb-6 hidden lg:block">
            {t("location_details")}
          </div>
          <div className="mt-5">
            <div className="mb-2 text-xs color-[#2F231C]">
              {`${t("location")}*:`}
            </div>
            <Input
              placeholder={t("enter_your_location") ?? ""}
              size="large"
              value={contactData.location}
              onChange={(event) =>
                onDataChange("location", String(event.target.value))
              }
            />
          </div>
          <div className="mt-5">
            <div className="mb-2 text-xs color-[#2F231C]">
              {`${t("name_on_the_doorbell")}*:`}
            </div>
            <Input
              placeholder={t("name_on_the_doorbell") ?? ""}
              size="large"
              value={contactData.beltLabel}
              onChange={(event) =>
                onDataChange("beltLabel", String(event.target.value))
              }
            />
          </div>
          <div className="mt-5 pb-5">
            <div className="mb-2 text-xs color-[#2F231C]">
              {`${t("reporter_role")}*:`}
            </div>
            <div className="grid grid-cols-[1fr_1fr_1fr] gap-4">
              {reporterRoles.map((role) => (
                <Card
                  className={classNames("h-[104px] capitalize", {
                    "border-[#6D8733] border": contactData.role === role,
                  })}
                  onClick={() => onDataChange("role", role)}
                  key={role}
                >
                  {t(role)}
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
