import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Container } from "../../../components/Container";
import { useTranslation } from "react-i18next";
import React from "react";
import { SubmitSuccessIcon } from "../../../icons";

export const ReportSuccess = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Container
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            className="w-full lg:w-fit"
            onClick={() => navigate(`/${accountName}`)}
          >
            {t("go_home")}
          </Button>
        </div>
      }
    >
      <div className="lg:flex flex-col items-center">
        <div className="text-2xl font-semibold text-[#2F231C] lg:text-4xl">
          {t("your_report_damage_has_been_sent")}
        </div>
        <div className="flex justify-center my-10 lg:my-24">
          <div className="h-[140px] w-[140px] mb-4">
            <SubmitSuccessIcon />
          </div>
        </div>
        <div className="text-[#626360]">
          <div>{t("report_submitted_description")}</div>
          <div className="mt-4">{t("report_submitted_questions")}</div>
        </div>
      </div>
    </Container>
  );
};
