import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import { Container } from "../../../components/Container";
import { findCategoryById } from "../../../utils/report/findCategoryById";
import {
  useReportDamageDataContext,
  useReportDamageActionsContext,
} from "../providers/ReportDamageProvider";

const tmpCategories = [
  {
    categoryName: "Sanitär",
    id: "77cd39b6-e8b8-11ed-a05b-0242ac1200000",
    icon: "sanitary.svg",
    locations: [
      {
        categoryName: "Wohnung",
        id: "a032485b-f795-4680-9d1d-31229afe1c65",
        icon: "indoor.svg",
      },
    ],
  },
  {
    categoryName: "Platten",
    id: "57cd39b6-e8b8-11ed-a05b-0242ac120001",
    icon: "tiles.svg",
    locations: [
      {
        categoryName: "Wohnung",
        id: "a032485b-f795-4680-9d1d-31229afe1c65",
        icon: "indoor.svg",
      },
    ],
  },
  {
    categoryName: "Maler",
    id: "57cd39b6-e8b8-11ed-a05b-0242ac120003",
    icon: "painting.svg",
    locations: [
      {
        categoryName: "Wohnung",
        id: "a032485b-f795-4680-9d1d-31229afe1c65",
        icon: "indoor.svg",
      },
      {
        categoryName: "Aussenbereich",
        id: "8641a7e0-cc46-4262-9d09-8b7677bf2e62",
        icon: "outdoor.svg",
      },
    ],
  },
];

export const ReportCameraResults = () => {
  const { accountName } = useParams();

  const navigate = useNavigate();

  const { setSelectedCategoryNamesTree, setDetailsFiles } =
    useReportDamageActionsContext();
  const { aiFile, categories } = useReportDamageDataContext();

  const [categoryId, setCategoryId] = useState<string | null>(
    tmpCategories[0].id
  );
  const [locationId, setLocationId] = useState<string | null>(null);

  useEffect(() => {
    if (!aiFile) navigate(`/${accountName}/report/camera`);
  }, [aiFile, accountName, navigate]);

  const category = useMemo(
    () => tmpCategories.find((cat) => cat.id === categoryId),
    [categoryId]
  );

  useEffect(() => {
    setLocationId(category?.locations[0]?.id ?? null);
  }, [category?.locations]);

  const onContinue = () => {
    if (!categoryId || !aiFile) return;

    const { selectedIds } = findCategoryById(categories, categoryId);

    setSelectedCategoryNamesTree(selectedIds);
    setDetailsFiles((prev) => [...prev, aiFile]);

    navigate(`/${accountName}/report/details`);
  };

  return (
    <Container
      reportStep={1}
      steps={[
        {
          label: t("camera"),
          step: 1,
        },
        {
          label: t("details"),
          step: 2,
        },
        {
          label: t("contacts"),
          step: 3,
        },
      ]}
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            onClick={onContinue}
            className="w-full lg:w-fit"
          >
            {t("continue")}
          </Button>
        </div>
      }
    >
      <div className="lg:flex justify-center items-center relative lg:mb-6">
        <Link
          to={`/${accountName}/report/camera`}
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{t("photo_recognition")}</div>
        </Link>
      </div>

      <div className="flex justify-center">
        <div className="max-w-2xl w-full">
          <div className="grid grid-cols-[80px_1fr] gap-4 p-4 bg-white rounded shadow-md mt-4">
            {aiFile?.originFileObj && (
              <img
                src={URL.createObjectURL(aiFile.originFileObj)}
                alt="Uploaded"
                className="w-20 h-20 object-cover"
              />
            )}

            <div className="flex flex-col justify-between">
              <div className="text-[#2F231C] text-2xl">
                {category?.categoryName}
              </div>
              <div className="text-[#626360]">{t("select_object")}</div>
            </div>
          </div>

          <div className="mt-6 lg:mt-10">
            <div className="text-sm text-[#2F231C]">{t("object_category")}</div>
            <div className="grid grid-cols-[1fr_1fr_1fr] gap-4 mt-4">
              {tmpCategories.map((category) => {
                const categoryIcon = category.icon.split(".")[0];

                return (
                  <Card
                    key={category.id}
                    icon={
                      categoryIcon === "default" ? "image-outline" : undefined
                    }
                    categoryIcon={
                      categoryIcon !== "default" ? categoryIcon : undefined
                    }
                    selected={category.id === categoryId}
                    onClick={() => setCategoryId(category.id)}
                    smallIcons
                    className="p-4"
                  >
                    {category.categoryName}
                  </Card>
                );
              })}
            </div>

            <div className="text-sm text-[#2F231C] mt-4">
              {t("damage_location")}
            </div>

            <div className="grid grid-cols-[1fr_1fr_1fr] gap-4 mt-4">
              {category?.locations.map((location) => {
                const categoryIcon = location.icon.split(".")[0];

                return (
                  <Card
                    key={location.id}
                    icon={
                      categoryIcon === "default" ? "image-outline" : undefined
                    }
                    categoryIcon={
                      categoryIcon !== "default" ? categoryIcon : undefined
                    }
                    selected={location.id === locationId}
                    onClick={() => setLocationId(location.id)}
                    smallIcons
                    className="p-4"
                  >
                    {location.categoryName}
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
