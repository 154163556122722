import { useCallback, useEffect, useState } from "react";
import { fetchWrapper } from "../utils/shared/fetchWrapper";
import { Card } from "../components/Card";
import { useTranslation } from "react-i18next";

interface AccountType {
  accountName: string;
  color: string;
  logoUrl: string;
}

export const WelcomePage = () => {
  const [accounts, setAccounts] = useState<AccountType[]>([]);

  const { t } = useTranslation();

  const fetchAccounts = useCallback(async () => {
    const response = await fetchWrapper<AccountType[]>("Accounts");
    setAccounts(response);
  }, []);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <div className="p-4">
      <div className="text-2xl font-semibold text-[#2F231C] sm:text-center">
        {t("welcome")}
      </div>
      <div className="text-[#626360] mt-1 sm:text-center">
        {t("please_select_property_management_company")}
      </div>
      <div className="mt-8 flex flex-col sm:flex-row sm:justify-center">
        {accounts.map((account) => (
          <Card
            className="h-[112px] cursor-pointer mb-4 sm:max-w-[295px] sm:w-full sm:ml-6"
            link={`/${account.accountName}`}
            key={account.accountName}
          >
            <div className="flex flex-col">
              <img
                className="h-6"
                src={account.logoUrl}
                alt={`${account} logo`}
              />
              <div className="text-[#626360] mt-6">{`${account.accountName} company`}</div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
