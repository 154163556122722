import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { useQuestionsData } from "../hooks/useQuestionsData";

type UseQuestionsDataResponse = ReturnType<typeof useQuestionsData>;

type QuestionsDataTypes = Pick<
  UseQuestionsDataResponse,
  | "isLoading"
  | "questions"
  | "selectedQuestionId"
  | "selectedSubTopicId"
  | "description"
  | "fields"
  | "detailsFiles"
  | "contactData"
>;

type QuestionsActionsTypes = Pick<
  UseQuestionsDataResponse,
  | "setSelectedQuestionId"
  | "setSelectedSubTopicId"
  | "setDescription"
  | "setFields"
  | "setDetailsFiles"
  | "setContactData"
  | "submitQuestion"
  | "setInitialQuestionsState"
>;

const initialData: QuestionsDataTypes = {
  isLoading: false,
  questions: [],
  selectedQuestionId: null,
  selectedSubTopicId: null,
  description: "",
  fields: [],
  detailsFiles: [],
  contactData: {
    name: "",
    phoneNumber: "",
    email: "",
    location: "",
  },
};

const initialsActions: QuestionsActionsTypes = {
  setSelectedQuestionId: () => Promise.resolve(),
  setSelectedSubTopicId: () => Promise.resolve(),
  setDescription: () => Promise.resolve(),
  setFields: () => Promise.resolve(),
  setDetailsFiles: () => Promise.resolve(),
  setContactData: () => Promise.resolve(),
  submitQuestion: () => Promise.resolve(),
  setInitialQuestionsState: () => Promise.resolve(),
};

const QuestionsDataContext = createContext<QuestionsDataTypes>(initialData);
const QuestionsActionsContext =
  createContext<QuestionsActionsTypes>(initialsActions);

interface ProviderProps {
  children: ReactNode;
  accountName: string;
}

export const QuestionsProvider = ({ children, accountName }: ProviderProps) => {
  const {
    isLoading,
    questions,
    selectedQuestionId,
    description,
    fields,
    detailsFiles,
    contactData,

    selectedSubTopicId,
    setDescription,
    setSelectedSubTopicId,
    setSelectedQuestionId,
    setFields,
    setDetailsFiles,
    setContactData,
    submitQuestion,
    setInitialQuestionsState,
  } = useQuestionsData({ accountName });

  const dataValue = useMemo(
    () => ({
      isLoading,
      questions,
      description,
      selectedSubTopicId,
      selectedQuestionId,
      fields,
      detailsFiles,
      contactData,
    }),
    [
      isLoading,
      questions,
      selectedQuestionId,
      description,
      selectedSubTopicId,
      fields,
      detailsFiles,
      contactData,
    ]
  );

  const actionsValue = useMemo(
    () => ({
      setSelectedQuestionId,
      setSelectedSubTopicId,
      setDescription,
      setFields,
      setDetailsFiles,
      setContactData,
      submitQuestion,
      setInitialQuestionsState,
    }),
    [
      setSelectedQuestionId,
      setSelectedSubTopicId,
      setDescription,
      setFields,
      setDetailsFiles,
      setContactData,
      submitQuestion,
      setInitialQuestionsState,
    ]
  );

  return (
    <QuestionsDataContext.Provider value={dataValue}>
      <QuestionsActionsContext.Provider value={actionsValue}>
        {children}
      </QuestionsActionsContext.Provider>
    </QuestionsDataContext.Provider>
  );
};

export const useQuestionsDataContext = () => useContext(QuestionsDataContext);
export const useQuestionsActionsContext = () =>
  useContext(QuestionsActionsContext);
