import { t } from "i18next";
import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Container } from "../../components/Container";
import {
  useQuestionsActionsContext,
  useQuestionsDataContext,
} from "./providers/QuestionsProvider";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";

export const QuestionsContact = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { setSelectedQuestionId, setContactData } =
    useQuestionsActionsContext();
  const { contactData } = useQuestionsDataContext();

  const onDataChange = (field: keyof typeof contactData, value: string) => {
    setContactData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Container
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            onClick={() => navigate(`/${accountName}/questions/confirm`)}
            className="w-full lg:w-fit"
          >
            {t("continue")}
          </Button>
        </div>
      }
      reportStep={2}
      steps={[
        {
          label: t("message"),
          step: 1,
        },
        {
          label: t("contacts"),
          step: 2,
        },
      ]}
    >
      <div className="lg:flex justify-center items-center relative lg:mb-6">
        <Link
          to={`/${accountName}/questions/details`}
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{t("message")}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl">
          {t("person_locations")}
        </div>
        <div />
      </div>

      <div>
        <div className="mt-5">
          <div className="mb-2 text-xs color-[#2F231C]">{`${t("name")}*:`}</div>
          <Input
            placeholder={t("question_full_name") ?? ""}
            size="large"
            value={contactData.name}
            onChange={(event) =>
              onDataChange("name", String(event.target.value))
            }
          />
        </div>
        <div className="mt-5">
          <div className="mb-2 text-xs color-[#2F231C]">
            {`${t("phone_number")}*:`}
          </div>
          <Input
            placeholder={t("phone_number") ?? ""}
            size="large"
            value={contactData.phoneNumber}
            onChange={(event) =>
              onDataChange("phoneNumber", String(event.target.value))
            }
          />
        </div>
        <div className="mt-5">
          <div className="mb-2 text-xs color-[#2F231C]">
            {`${t("e_mail")}*:`}
          </div>
          <Input
            placeholder={t("e_mail_adress") ?? ""}
            size="large"
            value={contactData.email}
            onChange={(event) =>
              onDataChange("email", String(event.target.value))
            }
          />
        </div>
        <div className="mt-5">
          <div className="mb-2 text-xs color-[#2F231C]">
            {`${t("residential_address")}*:`}
          </div>
          <Input
            placeholder={t("residential_address") ?? ""}
            size="large"
            value={contactData.location}
            onChange={(event) =>
              onDataChange("location", String(event.target.value))
            }
          />
        </div>
      </div>
    </Container>
  );
};
