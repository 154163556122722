import React from "react";

export const ImageDetectionIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorFirst}
        d="M1.62109,24.45654a1.50007,1.50007,0,0,1-1.5-1.5V1.62158a1.50008,1.50008,0,0,1,1.5-1.5h21.335a1.5,1.5,0,1,1,0,3H3.12109v19.835A1.50007,1.50007,0,0,1,1.62109,24.45654Z"
      />
      <path
        fill={colorFirst}
        d="M22.95605,79.87842H1.62109a1.50008,1.50008,0,0,1-1.5-1.5v-21.335a1.5,1.5,0,0,1,3,0v19.835h19.835a1.5,1.5,0,1,1,0,3Z"
      />
      <path
        fill={colorFirst}
        d="M78.37891,79.87842H57.044a1.5,1.5,0,1,1,0-3h19.835v-19.835a1.5,1.5,0,1,1,3,0v21.335A1.50008,1.50008,0,0,1,78.37891,79.87842Z"
      />
      <path
        fill={colorFirst}
        d="M78.37891,24.45654a1.50007,1.50007,0,0,1-1.5-1.5V3.12158H57.044a1.5,1.5,0,0,1,0-3h21.335a1.50008,1.50008,0,0,1,1.5,1.5v21.335A1.50007,1.50007,0,0,1,78.37891,24.45654Z"
      />
      <path
        fill={colorSecond}
        d="M59.21454,19.23737H20.78546a1.5481,1.5481,0,0,0-1.54809,1.54809V59.21454a1.548,1.548,0,0,0,1.54809,1.548H59.21454a1.548,1.548,0,0,0,1.54809-1.548V20.78546A1.5481,1.5481,0,0,0,59.21454,19.23737ZM41.27386,24.98016A5.4979,5.4979,0,1,1,35.77594,30.478,5.4979,5.4979,0,0,1,41.27386,24.98016Zm15.68219,22.428a1.49944,1.49944,0,0,1-2.12109,0l-5.9248-5.92382L43.3949,46.99963l6.95959,6.95984a1.49984,1.49984,0,1,1-2.12109,2.1211L40.21509,48.062l-.0022-.00146-.00146-.0022-7.98389-7.98413L25.26562,48.5918a1.49975,1.49975,0,1,1-2.32226-1.89844L30.9541,36.8916a1.49951,1.49951,0,0,1,2.22168-.11133l8.098,8.09827,6.57581-6.57581a1.49946,1.49946,0,0,1,2.12109,0l6.98535,6.98438A1.49944,1.49944,0,0,1,56.95605,47.4082Z"
      />
    </svg>
  );
};
