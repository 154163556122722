import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Container } from "../../../components/Container";
import { TextArea } from "../../../components/TextArea";
import { Upload } from "../../../components/Upload";
import type { UploadProps } from "antd/es/upload/interface";
import {
  useReportDamageActionsContext,
  useReportDamageDataContext,
} from "../providers/ReportDamageProvider";
import { useTranslation } from "react-i18next";

export const ReportDetails = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { detailsDescription, selectedCategoryName, detailsFiles } =
    useReportDamageDataContext();
  const {
    setDetailsFiles,
    setDetailsDescription,
    setSelectedCategoryNamesTree,
  } = useReportDamageActionsContext();

  const { t } = useTranslation();

  const onFilesChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setDetailsFiles(newFileList);
  };

  return (
    <Container
      reportStep={2}
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            onClick={() => navigate(`/${accountName}/report/contacts`)}
            className="w-full lg:w-fit"
            disabled={!detailsDescription}
          >
            {t("continue")}
          </Button>
        </div>
      }
    >
      <div className="lg:flex justify-center items-center relative">
        <Link
          to={`/${accountName}/report`}
          onClick={() =>
            setSelectedCategoryNamesTree((prev) =>
              prev.slice(0, prev.length - 1)
            )
          }
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{selectedCategoryName}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl">
          {t("describe_the_damage")}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="max-w-2xl w-full">
          <div className="w-full">
            <div className="text-xs text-[#2F231C] mb-2">{`${t(
              "description"
            )}*:`}</div>
            <TextArea
              placeholder={t("enter_your_description_here") ?? ""}
              rows={8}
              value={detailsDescription}
              onChange={(event) => setDetailsDescription(event.target.value)}
            />
          </div>
          <div className="mt-4 w-full">
            <Upload
              onChange={onFilesChange}
              fileList={detailsFiles}
              listType="picture"
              multiple
              className="w-full"
              beforeUpload={() => false}
            >
              <Button
                className="w-full flex justify-center"
                size="large"
                ghost
                type="primary"
              >
                <div className="flex">
                  <i data-eva="attach-outline" data-eva-fill="#6D8733" />
                  <div className="ml-2">{t("add_photo_or_file")}</div>
                </div>
              </Button>
            </Upload>
          </div>
        </div>
      </div>
    </Container>
  );
};
