import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "./utils/shared/languages";

i18n.use(initReactI18next).init({
  lng: languages.en,
  fallbackLng: languages.en,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    [languages.en]: {
      translation: {
        welcome: "Welcome",
        how_might_we_help_you: "How may we help you?",
        report_damage_manually: "Report damage manually",
        administrative_questions: "Administrative questions",
        determine_the_type_of_damage_by_photo:
          "Determine the type of damage by photo",

        home: "Home",
        where_is_the_damage: "Where is the damage?",
        which_object_is_affected: "Which object is affected?",
        object: "Object",
        frequently_used: "Frequently used",

        describe_the_damage: "Describe the damage",
        description: "Description",
        enter_your_description_here: "Enter your description here",
        add_photo_or_file: "Add photo or file",
        add_more: "Add more",
        continue: "Continue",

        details: "Details",
        how_can_we_contact_you: "How can we contact you?",
        full_name: "Full name",
        enter_full_name: "Enter full name",
        phone_number: "Phone number",
        enter_phone_number: "Enter phone number",
        email: "Email",
        enter_your_email: "Enter your email",
        location: "Location",
        enter_your_location: "Enter your location",
        reporter_role: "Reporter role",
        confirm_contact_data: "Confirm contact data",

        contacts: "Contact",
        please_enter_the_code_from_email: "Please, enter the code from email",
        incorrect_code: "Incorrect code",
        dont_have_any_email_please_check_spam_folder_or:
          "Don’t have any email? Please, check spam folder or ",
        resend_the_code: "resend the code",
        report_damage: "Report damage",

        your_report_damage_has_been_sent: "Your report damage has been sent!",
        report_submitted_description:
          "Your report damage has been successfully submitted and we will get back to you as soon as possible take care of processing.",
        report_submitted_questions:
          "If you have any questions, please feel free to contact us via our regular communication channels.",
        go_home: "Go home",

        please_select_property_management_company:
          "Please select property management company to proceed",

        category: "Category",

        tenant: "Tenant",
        janitor: "Janitor",
        others: "Other",

        choose_topic: "Choose topic",
        topics: "Topics",
        ask_another_question: "Ask another question",
        topic: "Topic",

        person_locations: "Person & Location",
        name: "Name",
        message: "Message",
        question_full_name: "Full name",
        e_mail: "E-Mail",
        e_mail_adress: "E-Mail address",
        residential_address: "Residential address",
        confirm_contact_details: "Confirm contact details",

        submit_question: "Submit question",

        name_on_the_doorbell: "Name on the doorbell",

        photo_recognition: "Camera recognition",
        camera: "Camera",
        photo_recognition_description:
          "Just take a photo of object you want and upload it, and our AI will recognise this object",
        upload_manually: "Upload manually",
        please_take_a_photo:
          "Please, take a photo or upload it manually by the buttons below",
        ai_analysing: "Our AI analysing your photo",
        select_object: "Select the correct object details to continue",

        object_category: "Object category",
        damage_location: "Damage location",

        thank_for_question: "Thank you for your question",
        submitted_request:
          "Your request was successfully submitted and we will get back to you",

        contact_information: "Contact information",
        location_details: "Location details",
      },
    },
    [languages.de]: {
      translation: {
        welcome: "Willkommen",
        how_might_we_help_you: "Wie können wir Ihnen helfen?",
        report_damage_manually: "Schaden melden",
        administrative_questions: "Fragen",
        determine_the_type_of_damage_by_photo: "Schadenerkennung per Foto",

        home: "Home",
        where_is_the_damage: "Wo ist der Schaden?",
        which_object_is_affected: "Welches Objekt ist betroffen?",
        object: "Objekt",
        frequently_used: "Häufig verwendet",

        describe_the_damage: "Beschreibe den Schaden",
        description: "Beschreibung",
        enter_your_description_here: "Füge hier die Beschreibung ein",
        add_photo_or_file: "Fotos oder Dateien hinzufügen",
        add_more: "Weiterer Schaden",
        continue: "Weiter",

        details: "Details",
        how_can_we_contact_you: "Wie können wir dich kontaktieren?",
        full_name: "Name",
        enter_full_name: "Name eingeben",
        phone_number: "Mobilnummer",
        enter_phone_number: "Mobilnummer eingeben",
        email: "Email",
        enter_your_email: "Email eingeben",
        location: "Ort",
        enter_your_location: "Ort eingeben",
        reporter_role: "Rolle",
        confirm_contact_data: "Weiter",

        contacts: "Kontakt",
        please_enter_the_code_from_email:
          "Ein letzter Schritt: Geben Sie den zugesendeten Code ein",
        incorrect_code: "Fehlerhafter Code",
        dont_have_any_email_please_check_spam_folder_or:
          "Keine E-Mail erhalten? Prüfen Sie den Spam-Ordner oder ",
        resend_the_code: " lassen Sie sich den Code per SMS senden.",
        report_damage: "Schaden melden",

        your_report_damage_has_been_sent: "Danke für Ihre Meldung",
        report_submitted_description:
          "Deine Schadensmeldung wurde erfolgreich übermittelt und wird schnellst möglich bearbeitet.",
        report_submitted_questions:
          "Wenn du Fragen hast, kannst du uns jderzeit über unsere regulären Kontaktwege erreichen.",
        go_home: "Zum Anfang",

        please_select_property_management_company:
          "Bitte wähle deine zuständige Immobilienverwaltung.",

        category: "Kategorie",

        tenant: "Mieter",
        janitor: "Hauswart",
        others: "Weiteres",

        choose_topic: "Zu welchem Themengebiet?",
        topics: "Themengebiete",
        ask_another_question: "Weitere Frage stellen",
        topic: "Themengebiet",

        person_locations: "Person & Ort",
        name: "Name",
        message: "Nachricht",
        question_full_name: "Vollständiger Name",
        e_mail: "E-Mail",
        e_mail_adress: "E-Mailadresse",
        residential_address: "Wohnadresse",
        confirm_contact_details: "Kontaktdaten bestätigen",

        submit_question: "Frage übermitteln",

        name_on_the_doorbell: "Beschriftung der Klingel",

        photo_recognition: "Bilderkennung",
        photo_recognition_description:
          "Nehmen Sie einfach ein Foto vom Schaden auf und wir werden die Kategorie vorschlagen.",
        upload_manually: "Foto hochladen",
        please_take_a_photo:
          "Bitte machen Sie ein Foto oder laden Sie es manuell über die folgenden Schaltflächen hoch",
        camera: "Bilderkennung",
        ai_analysing: "Lehnen Sie sich zurück, wir analysieren das Foto",

        select_object: "Bestätigen Sie die erkannten Schäden.",
        object_category: "Schadenskategorie",
        damage_location: "Schadensort",

        thank_for_question: "Danke für Ihre Frage",
        submitted_request:
          "Ihr Anliegen wurde erfolgreich übermittelt und wir werden uns bei Ihnen melden.",

        contact_information: "Kontaktinformationen ",
        location_details: "Standortdetails",
      },
    },
  },
});

export default i18n;
