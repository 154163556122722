import React from "react";

export const DoorsWindowsIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorFirst}
        d="M66.93652,70.56787H66.001V1.62158a1.50008,1.50008,0,0,0-1.5-1.5H15.499a1.50008,1.50008,0,0,0-1.5,1.5V70.56787h-.93554a1.50007,1.50007,0,0,0-1.5,1.5v6.31055a1.50007,1.50007,0,0,0,1.5,1.5h53.873a1.50007,1.50007,0,0,0,1.5-1.5V72.06787A1.50007,1.50007,0,0,0,66.93652,70.56787ZM16.999,3.12158H63.001V70.56787H16.999Zm48.4375,73.75684h-50.873V73.56787h50.873Z"
      />
      <rect
        fill={colorSecond}
        x="20.76491"
        y="7.62524"
        width="17.51918"
        height="27.59037"
        rx="0.7007"
      />
      <rect
        fill={colorSecond}
        x="41.71591"
        y="7.62524"
        width="17.51918"
        height="27.59037"
        rx="0.7007"
      />
      <rect
        fill={colorSecond}
        x="20.76491"
        y="38.47366"
        width="17.51918"
        height="27.59037"
        rx="0.7007"
      />
      <rect
        fill={colorSecond}
        x="41.71591"
        y="38.47366"
        width="17.51918"
        height="27.59037"
        rx="0.7007"
      />
    </svg>
  );
};
