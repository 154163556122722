import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { Container } from "../../components/Container";
import { useTranslation } from "react-i18next";
import { InputNumber } from "../../components/InputNumber";
import React from "react";
import {
  useQuestionsActionsContext,
  useQuestionsDataContext,
} from "./providers/QuestionsProvider";

const emptyCodeValues = new Array(4).fill("");
const correctCode = ["9", "8", "7", "6"];

export const QuestionsConfirm = () => {
  const { accountName } = useParams();

  const { t } = useTranslation();

  const { submitQuestion } = useQuestionsActionsContext();
  const { isLoading } = useQuestionsDataContext();

  const [isVerified, setIsVerified] = useState(false);
  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState(emptyCodeValues);
  const refs = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
  ];

  useEffect(() => {
    if (code.filter((elem) => elem).length !== 4) return;

    if (code.every((elem, index) => correctCode[index] === elem)) {
      setIsVerified(true);
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [code]);

  const onCodeChange = (index: number, value: string) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (value && index < 3) refs[index + 1]?.current?.focus();
  };

  return (
    <Container
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            onClick={submitQuestion}
            className="w-full lg:w-fit"
            disabled={!isVerified || isLoading}
          >
            {t("submit_question")}
          </Button>
        </div>
      }
    >
      <div className="lg:flex justify-center items-center relative">
        <Link
          to={`/${accountName}/report/contacts`}
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{t("contacts")}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl lg:m-0 text-center">
          {t("please_enter_the_code_from_email")}
        </div>
      </div>

      <div className="h-full lg:flex flex-col justify-center items-center">
        <div className="flex flex-col items-center">
          <div className="flex justify-between items-center">
            {emptyCodeValues.map((_, index) => (
              <Fragment key={index}>
                <InputNumber
                  className="w-14 h-[60px] text-center flex items-center"
                  maxLength={1}
                  value={code[index]}
                  onChange={(value) =>
                    onCodeChange(index, value ? String(value) : "")
                  }
                  ref={refs[index]}
                  isError={isError}
                  size="large"
                  inputMode="numeric"
                />
                {index !== emptyCodeValues.length - 1 && (
                  <div className="mx-3">-</div>
                )}
              </Fragment>
            ))}
          </div>

          {isError && (
            <div className="flex justify-center mt-4">
              <i
                data-eva="alert-triangle"
                data-eva-fill="#FF5747"
                data-eva-height="20"
                data-eva-width="20"
              />
              <span className="text-[#FF0000] ml-2">{t("incorrect_code")}</span>
            </div>
          )}

          <div className="text-sm text-[#2F231C] mt-4 text-center">
            {t("dont_have_any_email_please_check_spam_folder_or")}
            <u>{t("resend_the_code")}</u>
          </div>
        </div>
      </div>
    </Container>
  );
};
