import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CategoryType } from "../../../utils/report/types";
import { fetchWrapper } from "../../../utils/shared/fetchWrapper";
import { useTranslation } from "react-i18next";
import { findCategoryById } from "../../../utils/report/findCategoryById";
import { UploadFile } from "antd";
import { getFileBase64 } from "../../../utils/shared/getFileBase64";
import { RcFile } from "antd/es/upload";

interface Props {
  accountName: string;
}

interface ContactDataProps {
  fullName: string;
  phoneNumber: string;
  email: string;
  location: string;
  role: string;
  availablePeriod?: string;
  beltLabel: string;
}

interface AccountDataProps {
  accountName: string;
  colorFirst: string;
  colorSecond: string;
  logoUrl: string;
}

export const useReportDamageData = ({ accountName }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [frequentlyUsedCategories, setFrequentlyUsedCategories] = useState<
    CategoryType[]
  >([]);
  const [selectedCategoryNamesTree, setSelectedCategoryNamesTree] = useState<
    CategoryType["id"][]
  >([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [detailsDescription, setDetailsDescription] = useState("");
  const [detailsFiles, setDetailsFiles] = useState<UploadFile[]>([]);
  const [contactData, setContactData] = useState<ContactDataProps>({
    fullName: "",
    phoneNumber: "",
    email: "",
    location: "",
    role: "",
    beltLabel: "",
  });
  const [account, setAccount] = useState<AccountDataProps>({
    accountName: "",
    colorFirst: "#86a931",
    colorSecond: "#a0cbb8",
    logoUrl: "",
  });
  const [aiFile, setAIFile] = useState<UploadFile | null>(null);

  useEffect(() => {
    const { category } = findCategoryById(
      categories,
      selectedCategoryNamesTree.at(-1) ?? ""
    );
    setSelectedCategoryName(category?.categoryName ?? "");
  }, [selectedCategoryNamesTree, categories]);

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const fetchAccountData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await fetchWrapper<{
        categories: CategoryType[];
        frequentlyUsed: CategoryType[];
      }>(`Accounts/${accountName}/Categories?lang=${i18n.language}`);

      setCategories(response.categories);
      setFrequentlyUsedCategories(response.frequentlyUsed);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [accountName, i18n.language]);

  const fetchAccounts = useCallback(async (accountName: string) => {
    const accounts = await fetchWrapper<AccountDataProps[]>("Accounts");

    const currentAccount = accounts.find(
      (acc) => acc.accountName === accountName
    );

    if (currentAccount) setAccount(currentAccount);
  }, []);

  const sendReportDamage = useCallback(async () => {
    setIsLoading(true);

    const promises = detailsFiles.map(
      async (file) => await getFileBase64(file.originFileObj as RcFile)
    );
    const base64Files = await Promise.all(promises);

    try {
      await fetchWrapper(
        `Accounts/${accountName}/DamageReports`,
        {
          method: "POST",
        },
        {
          categoryId: selectedCategoryNamesTree.at(-1),
          reportLanguage: i18n.language,
          description: detailsDescription,
          reporterName: contactData.fullName,
          reporterEmail: contactData.email,
          reporterMobile: contactData.phoneNumber,
          reporterRole: contactData.role,
          location: contactData.location,
          files: base64Files,
          beltLabel: contactData.beltLabel,
        }
      );

      navigate(`/${accountName}/report/success`);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [
    accountName,
    selectedCategoryNamesTree,
    detailsDescription,
    contactData,
    detailsFiles,
    navigate,
    i18n.language,
  ]);

  useEffect(() => {
    fetchAccounts(accountName);
  }, [accountName, fetchAccounts]);

  useEffect(() => {
    fetchAccountData();
  }, [fetchAccountData]);

  return {
    isLoading,
    categories,
    frequentlyUsedCategories,
    setSelectedCategoryNamesTree,
    selectedCategoryNamesTree,
    setDetailsFiles,
    contactData,
    setContactData,
    sendReportDamage,
    setDetailsDescription,
    detailsDescription,
    selectedCategoryName,
    account,
    aiFile,
    setAIFile,
    detailsFiles,
  };
};
