import classNames from "classnames";
import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  activeStep: number;
  className?: string;
  steps?: { label: string; step: number }[];
}

export const Steps = ({ activeStep = 1, className, steps }: Props) => {
  const { t } = useTranslation();

  const stepsList = useMemo(
    () =>
      steps
        ? steps
        : [
            {
              label: t("category"),
              step: 1,
            },
            {
              label: t("details"),
              step: 2,
            },
            {
              label: t("contacts"),
              step: 3,
            },
          ],
    [t, steps]
  );

  return (
    <div className={classNames("w-full px-6 pb-5", className)}>
      <div className="flex justify-between relative">
        <div className="absolute bg-[#ECEDEB] h-1 w-full top-1/2 transform -translate-y-1/2 left-0" />

        {stepsList.map(({ step, label }) => (
          <div className="relative z-10" key={step}>
            <div
              className={classNames(
                "w-5 h-5 rounded-full bg-[#ECEDEB] flex justify-center items-center",
                {
                  "bg-[#ECEDEB]": activeStep !== step,
                  "bg-white shadow-xl": activeStep === step,
                }
              )}
            >
              {activeStep > step && (
                <i
                  data-eva="checkmark-outline"
                  data-eva-fill="#4B4D4A"
                  data-eva-width={10}
                />
              )}
              {activeStep === step && (
                <div className="rounded-full w-2 h-2 bg-[#6D8733]" />
              )}
            </div>

            <div className="absolute -left-1/2 top-6">
              <div
                className={classNames("text-xs", {
                  "text-[#4B4D4A]": step !== activeStep,
                  "text-[#6D8733]": step === activeStep,
                })}
                key={step}
              >
                {label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
