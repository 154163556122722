import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "../../../components/Container";
import { useTranslation } from "react-i18next";
import React from "react";
import { Upload } from "../../../components/Upload";
import { Button } from "../../../components/Button";
import { Spin, UploadProps } from "antd";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useReportDamageActionsContext,
  useReportDamageDataContext,
} from "../providers/ReportDamageProvider";

export const ReportCamera = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { aiFile } = useReportDamageDataContext();
  const { setAIFile } = useReportDamageActionsContext();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const onFilesChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setAIFile(newFileList[0]);
  };

  useEffect(() => {
    if (aiFile) {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        navigate(`/${accountName}/report/camera-result`);
      }, 2000);
    }
  }, [aiFile, accountName, navigate]);

  console.log(aiFile);

  return (
    <Container
      reportStep={1}
      steps={[
        {
          label: t("camera"),
          step: 1,
        },
        {
          label: t("details"),
          step: 2,
        },
        {
          label: t("contacts"),
          step: 3,
        },
      ]}
    >
      <div className="lg:flex justify-center items-center relative lg:mb-6">
        <Link
          to={`/${accountName}`}
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{t("home")}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] mt-4 mb-1 lg:text-4xl">
          {t("photo_recognition")}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="max-w-2xl ">
          <div className="text-[#626360]">
            {t("photo_recognition_description")}
          </div>

          <div className="flex justify-center items-center mt-4">
            <div className="w-[343px] h-[343px] relative">
              {aiFile?.originFileObj ? (
                <>
                  <img
                    src={URL.createObjectURL(aiFile.originFileObj)}
                    alt="Uploaded"
                    className={classNames("w-full h-full object-cover", {
                      "opacity-30": isLoading,
                    })}
                  />
                  {isLoading && (
                    <div className="absolute top-[calc(50%-40px)] left-0 right-0 mx-auto w-[60%] flex flex-col">
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 50 }} spin />
                        }
                      />
                      <div className="text-[#2F231C] text-sm mt-4 text-center">
                        {t("ai_analysing")}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="h-full bg-[#ECEDEB] flex items-center text-center text-[#626360]">
                  {t("please_take_a_photo")}
                </div>
              )}
            </div>
          </div>

          <div className="mt-2 w-full">
            <Upload
              onChange={onFilesChange}
              fileList={aiFile ? [aiFile] : []}
              listType={undefined}
              multiple
              className="w-full"
              beforeUpload={() => false}
            >
              <Button
                className="w-full flex justify-center"
                size="large"
                ghost
                type="primary"
                disabled={isLoading}
              >
                <div className="flex">
                  <i data-eva="attach-outline" data-eva-fill="#6D8733" />
                  <div className="ml-2">{t("upload_manually")}</div>
                </div>
              </Button>
            </Upload>
          </div>
        </div>
      </div>
    </Container>
  );
};
