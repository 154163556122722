import React from "react";

export const SanitaryIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorSecond}
        d="M16.89651,17.09955h4.9901a0,0,0,0,1,0,0V28.41712a0,0,0,0,1,0,0h-4.9901a1.18981,1.18981,0,0,1-1.18981-1.18981V18.28937A1.18981,1.18981,0,0,1,16.89651,17.09955Z"
      />
      <path
        fill={colorFirst}
        d="M63.4873,34.02441H60.19238V11.49219h.29492a3.91,3.91,0,0,0,3.90528-3.90625V4.02734A3.91,3.91,0,0,0,60.4873.12109H19.5127a3.91,3.91,0,0,0-3.90528,3.90625v3.5586a3.91,3.91,0,0,0,3.90528,3.90625h.874V34.02441h-3.874a3.91,3.91,0,0,0-3.90528,3.90625v3.5586a3.91,3.91,0,0,0,3.90528,3.90625h.2351a23.16009,23.16009,0,0,0,3.99127,11.60425L18.06348,73.25293a5.70123,5.70123,0,0,0,5.625,6.626h32.623a5.70145,5.70145,0,0,0,5.625-6.627L59.26093,56.99976A23.16009,23.16009,0,0,0,63.2522,45.39551h.2351a3.91,3.91,0,0,0,3.90528-3.90625v-3.5586A3.91,3.91,0,0,0,63.4873,34.02441ZM18.60742,7.58594V4.02734a.9067.9067,0,0,1,.90528-.90625H60.4873a.9067.9067,0,0,1,.90528.90625v3.5586a.9067.9067,0,0,1-.90528.90625H19.5127A.9067.9067,0,0,1,18.60742,7.58594Zm4.7793,3.90625H57.19238V34.02441H23.38672ZM58.97559,73.73926a2.70035,2.70035,0,0,1-2.66407,3.13965h-32.623a2.70013,2.70013,0,0,1-2.66407-3.13868l2.2461-13.64318a23.2502,23.2502,0,0,0,33.459,0ZM56.47845,55.73c-.01654.02216-.03076.04553-.04608.0686a20.26913,20.26913,0,0,1-32.86474,0c-.01532-.02307-.02954-.04644-.04608-.0686A20.178,20.178,0,0,1,19.749,45.39551H60.251A20.178,20.178,0,0,1,56.47845,55.73Zm7.91413-14.24072a.9067.9067,0,0,1-.90528.90625H16.5127a.9067.9067,0,0,1-.90528-.90625v-3.5586a.9067.9067,0,0,1,.90528-.90625H63.4873a.9067.9067,0,0,1,.90528.90625Z"
      />
    </svg>
  );
};
