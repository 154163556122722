import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { findCategoryById } from "../../../utils/report/findCategoryById";
import {
  useReportDamageActionsContext,
  useReportDamageDataContext,
} from "../providers/ReportDamageProvider";

export const useReportQueryParams = () => {
  const [searchParams] = useSearchParams();
  const [categoryId, setCategoryId] = useState<string | null>(null);

  const {
    setDetailsDescription,
    setContactData,
    setSelectedCategoryNamesTree,
  } = useReportDamageActionsContext();
  const { categories } = useReportDamageDataContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("description"))
      setDetailsDescription(searchParams.get("description") ?? "");

    if (
      searchParams.get("reporterFullName") ||
      searchParams.get("reporterMobile") ||
      searchParams.get("reporterEmail") ||
      searchParams.get("reporterLocation") ||
      searchParams.get("reporterRole") ||
      searchParams.get("beltLabel")
    )
      setContactData({
        fullName: searchParams.get("reporterFullName") ?? "",
        phoneNumber: searchParams.get("reporterMobile") ?? "",
        email: searchParams.get("reporterEmail") ?? "",
        location: searchParams.get("reporterLocation") ?? "",
        role: searchParams.get("reporterRole") ?? "",
        beltLabel: searchParams.get("beltLabel") ?? "",
      });

    setCategoryId(searchParams.get("damageCategoryId") ?? null);
  }, [searchParams, setContactData, setDetailsDescription]);

  useEffect(() => {
    if (!categoryId || !categories.length) return;

    const { selectedIds } = findCategoryById(categories, categoryId);
    setSelectedCategoryNamesTree(selectedIds);

    navigate("report");
  }, [categoryId, categories, setSelectedCategoryNamesTree, navigate]);
};
