import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchWrapper } from "../../../utils/shared/fetchWrapper";
import { useTranslation } from "react-i18next";
import { UploadFile } from "antd";
import { getFileBase64 } from "../../../utils/shared/getFileBase64";
import { RcFile } from "antd/es/upload";

interface Props {
  accountName: string;
}

interface SubTopicType {
  description: string;
  name: string;
  questionsSubTopicId: string;
  extendedQuestionFields:
    | {
        example: string;
        name: string;
        questionFieldId: string;
      }[]
    | null;
}

interface QuestionType {
  questionsTopicId: string;
  name: string;
  icon: string;
  subTopics: SubTopicType[];
}

export const useQuestionsData = ({ accountName }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState<
    QuestionType["questionsTopicId"] | null
  >(null);

  const [selectedSubTopicId, setSelectedSubTopicId] = useState<
    SubTopicType["questionsSubTopicId"] | null
  >(null);
  const [description, setDescription] = useState("");
  const [fields, setFields] = useState<
    { questionFieldId: string; text: string }[]
  >([]);
  const [detailsFiles, setDetailsFiles] = useState<UploadFile[]>([]);

  const [contactData, setContactData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    location: "",
  });

  const setInitialQuestionsState = () => {
    setSelectedQuestionId(null);
    setSelectedSubTopicId(null);
    setDescription("");
    setFields([]);
    setDetailsFiles([]);
    setContactData({
      name: "",
      phoneNumber: "",
      email: "",
      location: "",
    });
  };

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const fetchQuestions = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await fetchWrapper<QuestionType[]>(
        `Accounts/${accountName}/QuestionsTopics?lang=${i18n.language}`
      );

      setQuestions(response);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [accountName, i18n.language]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const submitQuestion = useCallback(async () => {
    setIsLoading(true);

    const promises = detailsFiles.map(
      async (file) => await getFileBase64(file.originFileObj as RcFile)
    );
    const base64Files = await Promise.all(promises);

    try {
      await fetchWrapper(
        `Accounts/${accountName}/Questions`,
        {
          method: "POST",
        },
        {
          questionId: selectedQuestionId,
          questionSubTopicId: selectedSubTopicId,
          questionLanguage: i18n.language,
          questionText: description,
          questionsFieldInstances: fields,
          files: base64Files,

          name: contactData.name,
          phoneNumber: contactData.phoneNumber,
          email: contactData.email,
          location: contactData.location,
        }
      );

      navigate(`/${accountName}/questions/success`);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [
    selectedQuestionId,
    selectedSubTopicId,
    description,
    detailsFiles,
    navigate,
    contactData,
    i18n.language,
    accountName,
    fields,
  ]);

  return {
    isLoading,
    questions,
    selectedQuestionId,
    description,
    fields,
    detailsFiles,
    contactData,

    setSelectedQuestionId,
    selectedSubTopicId,
    setSelectedSubTopicId,
    setDescription,
    setFields,
    setDetailsFiles,
    setContactData,
    submitQuestion,
    setInitialQuestionsState,
  };
};
