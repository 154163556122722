import { TextArea } from "../../components/TextArea";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "../../components/Container";
import { Select } from "../../components/Select";
import {
  useQuestionsActionsContext,
  useQuestionsDataContext,
} from "./providers/QuestionsProvider";
import { Input } from "../../components/Input";
import { Upload, Button, UploadProps } from "antd";

export const QuestionsDetails = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    setDescription,
    setSelectedSubTopicId,
    setFields,
    setDetailsFiles,
    setSelectedQuestionId,
  } = useQuestionsActionsContext();
  const {
    questions,
    selectedQuestionId,
    description,
    selectedSubTopicId,
    fields,
    detailsFiles,
  } = useQuestionsDataContext();

  const selectedQuestion = useMemo(
    () => questions.find((q) => q.questionsTopicId === selectedQuestionId),
    [questions, selectedQuestionId]
  );

  const isQuestionSubTopics = !!selectedQuestion?.subTopics.length;

  const selectedSubTopic = useMemo(
    () =>
      selectedQuestion?.subTopics.find(
        (st) => st.questionsSubTopicId === selectedSubTopicId
      ),
    [selectedSubTopicId, selectedQuestion]
  );

  const onUpdateField = (id: string, text: string) => {
    const newFields = [...fields];

    const foundfieldIndex = newFields.findIndex(
      (f) => f.questionFieldId === id
    );

    if (foundfieldIndex !== -1) {
      newFields[foundfieldIndex].text = text;
    } else {
      newFields.push({
        questionFieldId: id,
        text,
      });
    }

    setFields(newFields);
  };

  const onFilesChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setDetailsFiles(newFileList);
  };

  return (
    <Container
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            onClick={() => navigate(`/${accountName}/questions/contact`)}
            className="w-full lg:w-fit"
            disabled={
              isQuestionSubTopics && !selectedSubTopic?.questionsSubTopicId
            }
          >
            {t("continue")}
          </Button>
        </div>
      }
      reportStep={1}
      steps={[
        {
          label: t("message"),
          step: 1,
        },
        {
          label: t("contacts"),
          step: 2,
        },
      ]}
    >
      <div className="lg:flex justify-center items-center relative lg:mb-6">
        <Link
          to={`/${accountName}/questions/${
            isQuestionSubTopics ? "description" : ""
          }`}
          className="flex items-center w-fit lg:absolute lg:left-0"
          onClick={() => !isQuestionSubTopics && setSelectedQuestionId(null)}
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{selectedQuestion?.name}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl">
          {t("ask_another_question")}
        </div>
        <div />
      </div>

      <div className="flex justify-center">
        <div className="max-w-2xl w-full">
          <div className="text-xs text-[#2F231C] mb-2">{t("topic")}</div>
          <Select
            options={selectedQuestion?.subTopics.map((t) => ({
              label: t.name,
              value: t.questionsSubTopicId,
            }))}
            onChange={(id: string) => {
              setSelectedSubTopicId(id);
              setFields([]);
            }}
            value={
              selectedSubTopic?.questionsSubTopicId
                ? {
                    value: selectedSubTopic.questionsSubTopicId,
                    label: selectedSubTopic.name,
                  }
                : null
            }
          />

          <div className="text-xs text-[#2F231C] mt-4">{t("description")}</div>
          <TextArea
            placeholder={t("enter_your_description_here") ?? ""}
            rows={8}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />

          {selectedSubTopic?.extendedQuestionFields?.map((field) => (
            <div key={field.questionFieldId}>
              <div className="mt-4 text-xs color-[#2F231C]">{field.name}</div>
              <Input
                placeholder={field.example}
                size="large"
                value={
                  fields.find(
                    (f) => f.questionFieldId === field.questionFieldId
                  )?.text ?? ""
                }
                onChange={(event) =>
                  onUpdateField(
                    field.questionFieldId,
                    String(event.target.value)
                  )
                }
              />
            </div>
          ))}

          <div className="mt-4 w-full">
            <Upload
              onChange={onFilesChange}
              fileList={detailsFiles}
              listType="picture"
              multiple
              className="w-full"
              beforeUpload={() => false}
            >
              <Button
                className="w-full flex justify-center"
                size="large"
                ghost
                type="primary"
              >
                <div className="flex">
                  <i data-eva="attach-outline" data-eva-fill="#6D8733" />
                  <div className="ml-2">{t("add_photo_or_file")}</div>
                </div>
              </Button>
            </Upload>
          </div>
        </div>
      </div>
    </Container>
  );
};
