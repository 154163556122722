import { Select as AntdSelect } from "antd";
import type { SelectProps } from "antd";
import classNames from "classnames";
import React from "react";

export const Select = (props: SelectProps) => {
  return (
    <AntdSelect
      {...props}
      className={classNames("w-full antd-select-wrapper", props.className)}
    />
  );
};
