import { t } from "i18next";
import React, { useEffect, useMemo } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Container } from "../../components/Container";
import { QuestionDescription } from "../../components/QuestionDescription";
import {
  useQuestionsActionsContext,
  useQuestionsDataContext,
} from "./providers/QuestionsProvider";
import { Button } from "../../components/Button";

export const QuestionsDescription = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { setSelectedQuestionId } = useQuestionsActionsContext();
  const { questions, selectedQuestionId } = useQuestionsDataContext();

  const selectedQuestion = useMemo(
    () => questions.find((q) => q.questionsTopicId === selectedQuestionId),
    [questions, selectedQuestionId]
  );

  useEffect(() => {
    if (!selectedQuestion) navigate(`/${accountName}/questions`);
  }, [selectedQuestion, navigate, accountName]);

  return (
    <Container
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            className="w-full lg:w-fit"
            onClick={() => navigate(`/${accountName}/questions/details`)}
          >
            {t("ask_another_question")}
          </Button>
        </div>
      }
    >
      <div className="lg:flex justify-center items-center relative lg:mb-6">
        <Link
          to={`/${accountName}/questions`}
          onClick={() => setSelectedQuestionId(null)}
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{t("topics")}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl">
          {selectedQuestion?.name}
        </div>
        <div />
      </div>

      <div className="flex justify-center">
        <div className="max-w-2xl w-full">
          {selectedQuestion?.subTopics?.map((topic) => (
            <QuestionDescription
              title={topic.name}
              description={topic.description}
              key={topic.questionsSubTopicId}
              className="mb-4"
            />
          ))}
        </div>
      </div>
    </Container>
  );
};
