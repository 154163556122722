import { CategoryType } from "./types";

export const findCategoryById = (
  categories: CategoryType[],
  categoryId: string,
  selectedIds: string[] = []
): { selectedIds: string[]; category: CategoryType | null } => {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === categoryId)
      return {
        category: categories[i],
        selectedIds: [...selectedIds, categories[i].id],
      };

    if (categories[i].children?.length) {
      const { category, selectedIds: childSelectedIds } = findCategoryById(
        categories[i].children || [],
        categoryId,
        [categories[i].id]
      );

      if (category)
        return { category, selectedIds: [...selectedIds, ...childSelectedIds] };
    }
  }

  return {
    category: null,
    selectedIds: [],
  };
};
