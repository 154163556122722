import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { useReportDamageData } from "../hooks/useReportDamageData";

type UseReportDamageDataResponse = ReturnType<typeof useReportDamageData>;

type ReportDataTypes = Pick<
  UseReportDamageDataResponse,
  | "isLoading"
  | "categories"
  | "frequentlyUsedCategories"
  | "selectedCategoryNamesTree"
  | "contactData"
  | "detailsDescription"
  | "selectedCategoryName"
  | "account"
  | "aiFile"
  | "detailsFiles"
>;
type ReportActionsTypes = Pick<
  UseReportDamageDataResponse,
  | "setSelectedCategoryNamesTree"
  | "setDetailsFiles"
  | "setContactData"
  | "sendReportDamage"
  | "setDetailsDescription"
  | "setAIFile"
>;

const initialData: ReportDataTypes = {
  isLoading: false,
  categories: [],
  frequentlyUsedCategories: [],
  selectedCategoryNamesTree: [],
  contactData: {
    fullName: "",
    phoneNumber: "",
    email: "",
    location: "",
    role: "",
    beltLabel: "",
  },
  detailsDescription: "",
  selectedCategoryName: "",
  account: {
    accountName: "",
    colorFirst: "#86a931",
    colorSecond: "#a0cbb8",
    logoUrl: "",
  },
  aiFile: null,
  detailsFiles: [],
};

const initialsActions: ReportActionsTypes = {
  setSelectedCategoryNamesTree: () => Promise.resolve(),
  setDetailsFiles: () => Promise.resolve(),
  setContactData: () => Promise.resolve(),
  sendReportDamage: () => Promise.resolve(),
  setDetailsDescription: () => Promise.resolve(),
  setAIFile: () => Promise.resolve(),
};

const ReportDamageDataContext = createContext<ReportDataTypes>(initialData);
const ReportDamageActionsContext =
  createContext<ReportActionsTypes>(initialsActions);

interface ProviderProps {
  children: ReactNode;
  accountName: string;
}

export const ReportDamageProvider = ({
  children,
  accountName,
}: ProviderProps) => {
  const {
    isLoading,
    categories,
    frequentlyUsedCategories,
    setSelectedCategoryNamesTree,
    selectedCategoryNamesTree,
    setDetailsFiles,
    contactData,
    setContactData,
    sendReportDamage,
    setDetailsDescription,
    detailsDescription,
    selectedCategoryName,
    account,
    aiFile,
    setAIFile,
    detailsFiles,
  } = useReportDamageData({ accountName });

  const dataValue = useMemo(
    () => ({
      isLoading,
      categories,
      frequentlyUsedCategories,
      selectedCategoryNamesTree,
      contactData,
      detailsDescription,
      selectedCategoryName,
      account,
      aiFile,
      detailsFiles,
    }),
    [
      isLoading,
      categories,
      frequentlyUsedCategories,
      selectedCategoryNamesTree,
      contactData,
      detailsDescription,
      selectedCategoryName,
      account,
      aiFile,
      detailsFiles,
    ]
  );

  const actionsValue = useMemo(
    () => ({
      setSelectedCategoryNamesTree,
      setDetailsFiles,
      setContactData,
      sendReportDamage,
      setDetailsDescription,
      setAIFile,
    }),
    [
      setSelectedCategoryNamesTree,
      setDetailsFiles,
      setContactData,
      sendReportDamage,
      setDetailsDescription,
      setAIFile,
    ]
  );

  return (
    <ReportDamageDataContext.Provider value={dataValue}>
      <ReportDamageActionsContext.Provider value={actionsValue}>
        {children}
      </ReportDamageActionsContext.Provider>
    </ReportDamageDataContext.Provider>
  );
};

export const useReportDamageDataContext = () =>
  useContext(ReportDamageDataContext);
export const useReportDamageActionsContext = () =>
  useContext(ReportDamageActionsContext);
