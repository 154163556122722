import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { Container } from "../../components/Container";
import { useTranslation } from "react-i18next";
import React from "react";
import { SubmitSuccessIcon } from "../../icons";
import { useQuestionsActionsContext } from "./providers/QuestionsProvider";

export const QuestionsSuccess = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { setInitialQuestionsState } = useQuestionsActionsContext();

  return (
    <Container
      bottomButtons={
        <div className="flex lg:justify-end">
          <Button
            size="large"
            type="primary"
            className="w-full lg:w-fit"
            onClick={() => {
              setInitialQuestionsState();
              navigate(`/${accountName}`);
            }}
          >
            {t("go_home")}
          </Button>
        </div>
      }
    >
      <div className="lg:flex flex-col items-center">
        <div className="text-2xl font-semibold text-[#2F231C] lg:text-4xl">
          {t("thank_for_question")}!
        </div>
        <div className="flex justify-center my-10 lg:my-24">
          <div className="h-[140px] w-[140px] mb-4">
            <SubmitSuccessIcon />
          </div>
        </div>
        <div className="text-[#626360] text-center">
          <div>{t("submitted_request")}</div>
        </div>
      </div>
    </Container>
  );
};
