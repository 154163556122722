import React from "react";

export const BillsIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorFirst}
        d="M59.9375,79.87891c-3.16406,0-4.7959-1.81641-6.10645-3.27539-1.30761-1.45606-2.13183-2.2793-3.873-2.2793-1.74024,0-2.56446.82324-3.8711,2.2793-1.30957,1.459-2.94043,3.27539-6.10253,3.27539-3.16114,0-4.79-1.81641-6.09864-3.27539-1.30566-1.45606-2.1289-2.2793-3.86621-2.2793-1.73926,0-2.5625.82324-3.86914,2.2793-1.30957,1.459-2.93945,3.27539-6.10059,3.27539-3.1621,0-4.791-1.81641-6.10058-3.27539-1.30664-1.45606-2.12988-2.2793-3.86719-2.2793a1.50039,1.50039,0,0,1-1.5-1.5V5.13574A5.02089,5.02089,0,0,1,13.59668.12109H66.40332A5.02089,5.02089,0,0,1,71.418,5.13574V72.82422a1.50039,1.50039,0,0,1-1.5,1.5c-1.74121,0-2.56641.82422-3.874,2.28027C64.73242,78.06348,63.10156,79.87891,59.9375,79.87891ZM49.958,71.32422c3.16308,0,4.79492,1.81543,6.10547,3.27441,1.30761,1.45606,2.13281,2.28028,3.874,2.28028s2.56641-.82422,3.874-2.28028a8.46193,8.46193,0,0,1,4.60645-3.125V5.13574a2.01636,2.01636,0,0,0-2.01465-2.01465H13.59668A2.01636,2.01636,0,0,0,11.582,5.13574V71.47363a8.44268,8.44268,0,0,1,4.59961,3.126c1.30664,1.45605,2.12988,2.2793,3.86816,2.2793s2.56153-.82325,3.86817-2.2793c1.30957-1.459,2.93945-3.27539,6.10156-3.27539,3.16113,0,4.79,1.8164,6.09863,3.27539,1.30567,1.45605,2.12891,2.2793,3.86622,2.2793,1.73925,0,2.56347-.82325,3.87011-2.2793C45.165,73.14062,46.79492,71.32422,49.958,71.32422Z"
      />
      <path
        fill={colorSecond}
        d="M47.41667,30.961a9.20867,9.20867,0,0,1-5.3574,2.41181v1.25493a.9778.9778,0,0,1-.9778.9778H39.30568a.9778.9778,0,0,1-.9778-.9778V33.49641a19.48571,19.48571,0,0,1-4.41343-.7957,13.956,13.956,0,0,1-2.67275-1.09086.9829.9829,0,0,1-.42618-1.26312L32.04106,27.596a.96433.96433,0,0,1,1.32946-.47206,13.6851,13.6851,0,0,0,2.50106,1.025,12.85363,12.85363,0,0,0,3.7907.59307q3.5436,0,3.5436-1.77427a1.46762,1.46762,0,0,0-1.00822-1.38383,18.89549,18.89549,0,0,0-3.24706-.944,31.32717,31.32717,0,0,1-4.10207-1.13672,6.9376,6.9376,0,0,1-2.83191-1.9423,5.246,5.246,0,0,1-1.17625-3.6029,6.22465,6.22465,0,0,1,1.92253-4.58641,9.2154,9.2154,0,0,1,5.565-2.34757V9.79873a.97779.97779,0,0,1,.9778-.9778h1.77579a.97779.97779,0,0,1,.9778.9778v1.166a18.052,18.052,0,0,1,3.40027.61778,13.37278,13.37278,0,0,1,2.07106.77377.98573.98573,0,0,1,.49294,1.26722l-1.15015,2.77478a.98576.98576,0,0,1-1.33847.49758,12.10055,12.10055,0,0,0-5.09176-1.21128,5.0662,5.0662,0,0,0-2.67376.54365,1.64032,1.64032,0,0,0-.84018,1.41842,1.40438,1.40438,0,0,0,.99339,1.30476,18.32347,18.32347,0,0,0,3.20258.89949,29.62932,29.62932,0,0,1,4.11689,1.13672,7.019,7.019,0,0,1,2.827,1.92747,5.12919,5.12919,0,0,1,1.201,3.58808A6.12825,6.12825,0,0,1,47.41667,30.961Z"
      />
      <path
        fill={colorFirst}
        d="M56.82715,45.42969H23.17285a1.5,1.5,0,0,1,0-3h33.6543a1.5,1.5,0,0,1,0,3Z"
      />
      <path
        fill={colorFirst}
        d="M56.82715,55.4707H23.17285a1.5,1.5,0,0,1,0-3h33.6543a1.5,1.5,0,0,1,0,3Z"
      />
      <path
        fill={colorFirst}
        d="M43.08594,65.5127H23.17285a1.5,1.5,0,1,1,0-3H43.08594a1.5,1.5,0,1,1,0,3Z"
      />
    </svg>
  );
};
