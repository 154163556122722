import React, { useState } from "react";
import classNames from "classnames";

interface Props {
  title: string;
  description: string;
  className?: string;
}

export const QuestionDescription = ({
  title,
  description,
  className,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={classNames("text-[#2F231C] cursor-pointer", className)}
      onClick={() => setOpen((prev) => !prev)}
    >
      <div className="flex justify-between p-4 bg-[#F6F8F6]">
        <div>{title}</div>

        <div>
          {open ? (
            <i
              data-eva="arrow-ios-upward-outline"
              data-eva-height={24}
              data-eva-width={24}
              data-eva-fill={"#626360"}
            />
          ) : (
            <i
              data-eva="arrow-ios-downward-outline"
              data-eva-height={24}
              data-eva-width={24}
              data-eva-fill={"#626360"}
            />
          )}
        </div>
      </div>

      {open && (
        <div className="bg-[#F6F8F6] p-4 text-[#4B4D4A] border-t border-[#D2D3D1]">
          {description}
        </div>
      )}
    </div>
  );
};
