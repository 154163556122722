import React from "react";

export const ClimateIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorFirst}
        d="M41.99512,54.24512V8.13281a8.01172,8.01172,0,1,0-16.02344,0V54.24512a13.9922,13.9922,0,1,0,16.02344,0ZM33.9834,76.69336A10.98265,10.98265,0,0,1,28.25586,56.334a1.50019,1.50019,0,0,0,.71582-1.27832V8.13281a5.01172,5.01172,0,1,1,10.02344,0V55.05566a1.50019,1.50019,0,0,0,.71582,1.27832A10.98265,10.98265,0,0,1,33.9834,76.69336Z"
      />
      <path
        fill={colorSecond}
        d="M36.29016,59.6178V23.54932a2.30664,2.30664,0,0,0-4.61328,0V59.6178a6.51178,6.51178,0,1,0,4.61328,0Z"
      />
      <path
        fill={colorSecond}
        d="M52.092,21.74965l-4.523,7.83414a1.01386,1.01386,0,0,0,.878,1.52079H57.493a1.01386,1.01386,0,0,0,.878-1.52079l-4.523-7.83414A1.01387,1.01387,0,0,0,52.092,21.74965Z"
      />
      <path
        fill={colorSecond}
        d="M53.848,45.77711l4.523-7.83413a1.01386,1.01386,0,0,0-.878-1.52079H48.447a1.01386,1.01386,0,0,0-.878,1.52079l4.523,7.83413A1.01386,1.01386,0,0,0,53.848,45.77711Z"
      />
    </svg>
  );
};
