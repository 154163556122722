import React from "react";

export const CommonSpaceIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorSecond}
        d="M72.69824,25.20013h-12.108a1.93464,1.93464,0,0,0-1.93463,1.93457V38.86163H44.613a1.93462,1.93462,0,0,0-1.93464,1.93463v11.5149H28.63568a1.9346,1.9346,0,0,0-1.93457,1.93463v9.9201H12.65845a1.93465,1.93465,0,0,0-1.93463,1.93463v12.278h63.909V27.1347A1.9346,1.9346,0,0,0,72.69824,25.20013Z"
      />
      <path
        fill={colorFirst}
        d="M3.62891,55.58691H7.45068a1.50048,1.50048,0,0,0,1.00489-.38623l8.75683-7.9021V60.062a1.5,1.5,0,0,0,3,0V44.59143L33.18994,32.88062V46.13574a1.5,1.5,0,0,0,3,0V30.17346L49.167,18.46307v13.7464a1.5,1.5,0,1,0,3,0V16.07227a1.50338,1.50338,0,0,0-.02923-.29L65.144,4.04553V18.28369a1.5,1.5,0,0,0,3,0V3.12158h6.48877a1.5,1.5,0,0,0,0-3h-9.0415a1.50051,1.50051,0,0,0-1.00488.38623L6.874,52.58691H3.62891a1.5,1.5,0,1,0,0,3Z"
      />
      <path
        fill={colorFirst}
        d="M77.8208,76.87842H2.1792a1.5,1.5,0,1,0,0,3H77.8208a1.5,1.5,0,1,0,0-3Z"
      />
    </svg>
  );
};
