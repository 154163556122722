import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card";
import { Container } from "../../components/Container";
import {
  useQuestionsActionsContext,
  useQuestionsDataContext,
} from "./providers/QuestionsProvider";

export const QuestionsList = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { questions, selectedQuestionId } = useQuestionsDataContext();
  const { setSelectedQuestionId } = useQuestionsActionsContext();

  const onQuestionClick = (id: string) => {
    setSelectedQuestionId(id);
  };

  useEffect(() => {
    if (!selectedQuestionId) return;

    const subTopics =
      questions.find((q) => q.questionsTopicId === selectedQuestionId)
        ?.subTopics ?? [];

    if (selectedQuestionId)
      navigate(subTopics.length ? "description" : "details");
  }, [selectedQuestionId, navigate, questions]);

  return (
    <Container>
      <div className="lg:flex justify-center items-center relative lg:mb-6">
        <Link
          to={`/${accountName}`}
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">{t("home")}</div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl">
          {t("choose_topic")}
        </div>
        <div />
      </div>

      <div className="grid gap-4 grid-cols-[1fr_1fr] lg:grid-cols-[1fr_1fr_1fr_1fr]">
        {questions.map((question) => (
          <Card
            key={question.questionsTopicId}
            onClick={() => onQuestionClick(question.questionsTopicId)}
            className="h-[164px]"
            icon={question.icon === "default" ? "image-outline" : undefined}
            categoryIcon={
              question.icon !== "default" ? question.icon : undefined
            }
          >
            {question.name}
          </Card>
        ))}
      </div>
    </Container>
  );
};
