import React from "react";

export const PaintingIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon Final</title>
      <path
        fill={colorSecond}
        d="M62.91534,27.21191H11.0589a5.53965,5.53965,0,0,1-5.53961-5.53967V36.29059A3.70942,3.70942,0,0,0,9.2287,40H64.74554A3.70943,3.70943,0,0,0,68.455,36.29059V21.67224A5.53966,5.53966,0,0,1,62.91534,27.21191Z"
      />
      <path
        fill={colorFirst}
        d="M35.916,57.49707a1.50007,1.50007,0,0,1-1.5-1.5V47.9585a4.308,4.308,0,0,1,4.30273-4.30323h32.959a1.30409,1.30409,0,0,0,1.30274-1.30322V17.21973a1.3041,1.3041,0,0,0-1.30274-1.30323H68.45508a1.5,1.5,0,0,1,0-3h3.22265a4.308,4.308,0,0,1,4.30274,4.30323V42.35205a4.30831,4.30831,0,0,1-4.30274,4.30322h-32.959A1.30409,1.30409,0,0,0,37.416,47.9585v8.03857A1.50008,1.50008,0,0,1,35.916,57.49707Z"
      />
      <path
        fill={colorFirst}
        d="M38.90527,79.87842H32.92676A3.92487,3.92487,0,0,1,29.00684,75.958V58.41748a3.92487,3.92487,0,0,1,3.91992-3.92041h5.97851a3.92488,3.92488,0,0,1,3.91993,3.92041V75.958A3.92488,3.92488,0,0,1,38.90527,79.87842ZM32.92676,57.49707a.92159.92159,0,0,0-.91992.92041V75.958a.92159.92159,0,0,0,.91992.92041h5.97851a.9216.9216,0,0,0,.91993-.92041V58.41748a.9216.9216,0,0,0-.91993-.92041Z"
      />
      <path
        fill={colorFirst}
        d="M62.915,28.71191H11.05859a7.04683,7.04683,0,0,1-7.03906-7.03955V7.16113A7.04684,7.04684,0,0,1,11.05859.12158H62.915a7.04767,7.04767,0,0,1,7.04,7.03955V21.67236A7.04766,7.04766,0,0,1,62.915,28.71191ZM11.05859,3.12158A4.04355,4.04355,0,0,0,7.01953,7.16113V21.67236a4.04355,4.04355,0,0,0,4.03906,4.03955H62.915a4.04438,4.04438,0,0,0,4.04-4.03955V7.16113a4.04438,4.04438,0,0,0-4.04-4.03955Z"
      />
    </svg>
  );
};
