import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { MainRoutes } from "./pages";
import { WelcomePage } from "./pages/WelcomePage";

export const RoutesConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/welcome" element={<WelcomePage />} />
        <Route index path="/:accountName/*" element={<MainRoutes />} />
        <Route path="*" element={<Navigate to="/welcome" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
