import React from "react";

export const GeneralQuestionIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorSecond}
        d="M15.01846,57.60612a2.94924,2.94924,0,0,1-2.94926-2.94926V25.83714H3.96655a2.34511,2.34511,0,0,0-2.34509,2.34517V73.88747a2.17669,2.17669,0,0,0,3.4773,1.74542l9.55016-7.11664H52.0022a2.34515,2.34515,0,0,0,2.34509-2.34517v-8.565Z"
      />
      <path
        fill={colorFirst}
        d="M75.63672,68.60254a4.22726,4.22726,0,0,1-2.52734-.84277h-.00049L61.49756,59.10645h-46.479a4.45449,4.45449,0,0,1-4.44921-4.44922V6.88086a4.45449,4.45449,0,0,1,4.44921-4.44922H75.4292a4.45449,4.45449,0,0,1,4.44922,4.44922V64.36133a4.24659,4.24659,0,0,1-4.2417,4.24121Zm-.73535-3.249a1.23779,1.23779,0,0,0,1.97705-.99219V6.88086A1.4509,1.4509,0,0,0,75.4292,5.43164H15.01855a1.45089,1.45089,0,0,0-1.44921,1.44922V54.65723a1.4509,1.4509,0,0,0,1.44921,1.44922H61.99512a1.5054,1.5054,0,0,1,.89648.29687Z"
      />
      <path
        fill={colorSecond}
        d="M43.60692,36.82646a1.18622,1.18622,0,0,1-1.184-1.22212c.11122-3.23181.81961-4.9141,4.30166-7.3743,1.65171-1.14333,2.62559-2.45613,2.62559-4.19258a3.81823,3.81823,0,0,0-4.10785-4.19258,4.43315,4.43315,0,0,0-4.48219,4.01883,1.07938,1.07938,0,0,1-1.0672.89343H35.83605a1.0987,1.0987,0,0,1-1.08688-1.198c.56965-6.4482,5.75586-8.71124,10.11242-8.71124,2.54086,0,10.84129.71969,10.84129,9.02012,0,3.43053-1.86354,5.463-5.12462,7.79205-1.57692,1.13705-2.36009,1.89312-2.54149,4.05618a1.19354,1.19354,0,0,1-1.179,1.11023Zm2.63881,8.80886H44.409a2.173,2.173,0,0,1-2.173-2.173V41.83688a2.173,2.173,0,0,1,2.173-2.173h1.83677a2.173,2.173,0,0,1,2.173,2.173v1.62548A2.173,2.173,0,0,1,46.24573,45.63532Z"
      />
    </svg>
  );
};
