import { Route, Routes, useParams } from "react-router-dom";
import { ReportConfirm } from "./Report/Damage/ReportConfirm";
import { ReportContacts } from "./Report/Damage/ReportContacts";
import { ReportDetails } from "./Report/Damage/ReportDetails";
import { ReportDamageProvider } from "./Report/providers/ReportDamageProvider";
import { ReportCategory } from "./Report/Damage/ReportCategory";
import { ReportSuccess } from "./Report/Damage/ReportSuccess";
import { HomePage } from "./HomePage";
import React from "react";
import { QuestionsProvider } from "./Questions/providers/QuestionsProvider";
import { QuestionsDescription } from "./Questions/QuestionsDescription";
import { QuestionsList } from "./Questions/QuestionsList";
import { QuestionsDetails } from "./Questions/QuestionsDetails";
import { QuestionsContact } from "./Questions/QuestionsContact";
import { QuestionsConfirm } from "./Questions/QuestionsConfirm";
import { QuestionsSuccess } from "./Questions/QuestionsSuccess";
import { ReportCamera } from "./Report/Damage/ReportCamera";
import { ReportCameraResults } from "./Report/Damage/ReportCameraResults";

export const MainRoutes = () => {
  const { accountName } = useParams();

  return (
    <ReportDamageProvider accountName={accountName ?? ""}>
      <QuestionsProvider accountName={accountName ?? ""}>
        <Routes>
          <Route
            index
            path="questions/description"
            element={<QuestionsDescription />}
          />
          <Route
            index
            path="questions/details"
            element={<QuestionsDetails />}
          />
          <Route
            index
            path="questions/contact"
            element={<QuestionsContact />}
          />
          <Route
            index
            path="questions/confirm"
            element={<QuestionsConfirm />}
          />
          <Route
            index
            path="questions/success"
            element={<QuestionsSuccess />}
          />
          <Route index path="questions" element={<QuestionsList />} />
        </Routes>
      </QuestionsProvider>

      <Routes>
        <Route index path="report/confirm" element={<ReportConfirm />} />
        <Route index path="report/contacts" element={<ReportContacts />} />
        <Route index path="report/details" element={<ReportDetails />} />
        <Route index path="report/success" element={<ReportSuccess />} />
        <Route index path="report/camera" element={<ReportCamera />} />
        <Route index path="report/camera" element={<ReportCamera />} />
        <Route
          index
          path="report/camera-result"
          element={<ReportCameraResults />}
        />
        <Route index path="report/" element={<ReportCategory />} />

        <Route index path="/" element={<HomePage />} />
      </Routes>
    </ReportDamageProvider>
  );
};
