import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card } from "../../../components/Card";
import { Container } from "../../../components/Container";
import { CategoryType } from "../../../utils/report/types";
import {
  useReportDamageActionsContext,
  useReportDamageDataContext,
} from "../providers/ReportDamageProvider";
import { useTranslation } from "react-i18next";
import { FrequentlyUsed } from "../components/FrequentlyUsed";
import React from "react";

export const ReportCategory = () => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { categories, selectedCategoryNamesTree, selectedCategoryName } =
    useReportDamageDataContext();
  const { setSelectedCategoryNamesTree } = useReportDamageActionsContext();

  const { t } = useTranslation();

  const [categoriesList, setCategoriesList] = useState<CategoryType[]>([]);

  useEffect(() => {
    if (!selectedCategoryNamesTree.length) setCategoriesList(categories);

    let resultList = [...categories];
    selectedCategoryNamesTree.forEach((id) => {
      const foundCategory = resultList.find((category) => category.id === id);

      if (foundCategory && !foundCategory?.children) navigate("details");

      resultList = foundCategory?.children ?? [];
    });

    setCategoriesList(resultList);
  }, [selectedCategoryNamesTree, categories, navigate]);

  const onCategoryClick = (id: string) => {
    setSelectedCategoryNamesTree((prev) => [...prev, id]);
  };

  const reportTitle = useMemo(() => {
    switch (selectedCategoryNamesTree.length) {
      case 0:
        return t("where_is_the_damage");
      case 1:
        return t("which_object_is_affected");
      case 2:
        return t("object");
      default:
        return "";
    }
  }, [selectedCategoryNamesTree.length, t]);

  return (
    <Container reportStep={1}>
      <div className="lg:flex justify-center items-center relative lg:mb-6">
        <Link
          to={`/${accountName}${
            selectedCategoryNamesTree.length ? "/report" : ""
          }`}
          onClick={() =>
            setSelectedCategoryNamesTree((prev) =>
              prev.slice(0, prev.length - 1)
            )
          }
          className="flex items-center w-fit lg:absolute lg:left-0"
        >
          <i data-eva="arrow-ios-back-outline" />
          <div className="text-[#2F231C] text-sm">
            {selectedCategoryName || t("home")}
          </div>
        </Link>
        <div className="text-2xl font-semibold text-[#2F231C] my-4 lg:text-4xl">
          {reportTitle}
        </div>
        <div />
      </div>

      <div className="grid gap-4 grid-cols-[1fr_1fr] lg:grid-cols-[1fr_1fr_1fr_1fr]">
        {categoriesList.map((category) => {
          const categoryIcon = category.icon.split(".")[0];

          return (
            <Card
              key={category.id}
              onClick={() => onCategoryClick(category.id)}
              className="h-[164px]"
              icon={categoryIcon === "default" ? "image-outline" : undefined}
              categoryIcon={
                categoryIcon !== "default" ? categoryIcon : undefined
              }
            >
              {category.categoryName}
            </Card>
          );
        })}
      </div>

      {!selectedCategoryNamesTree.length && <FrequentlyUsed />}
    </Container>
  );
};
