import { UserDataProvider } from "./providers/UserDataProvider";
import { RoutesConfig } from "./RoutesConfig";

function App() {
  return (
    <div>
      <UserDataProvider>
        <RoutesConfig />
      </UserDataProvider>
    </div>
  );
}

export default App;
