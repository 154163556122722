import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import { findCategoryById } from "../../../utils/report/findCategoryById";
import {
  useReportDamageDataContext,
  useReportDamageActionsContext,
} from "../providers/ReportDamageProvider";

export const FrequentlyUsed = ({ redirect }: { redirect?: boolean }) => {
  const { frequentlyUsedCategories, categories } = useReportDamageDataContext();
  const { setSelectedCategoryNamesTree } = useReportDamageActionsContext();
  const [redirectToReport, setRedirectToReport] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onCategoryClick = useCallback(
    (id: string) => {
      const { selectedIds } = findCategoryById(categories, id);
      setSelectedCategoryNamesTree(selectedIds);

      if (redirect) setRedirectToReport(true);
    },
    [categories, redirect, setSelectedCategoryNamesTree]
  );

  useEffect(() => {
    if (redirectToReport) navigate("report");
  }, [redirectToReport, navigate]);

  return (
    <div className="mt-8 lg:mt-12">
      <div className="text-2xl font-semibold text-[#2F231C] mb-4 lg:text-4xl lg:text-center">
        {t("frequently_used")}
      </div>
      <div className="flex overflow-auto pb-2">
        {frequentlyUsedCategories.map((category) => {
          const categoryIcon = category.icon.split(".")[0];

          return (
            <Card
              key={category.categoryName}
              onClick={() => onCategoryClick(category.id)}
              className="h-[100px] min-w-[100px] mr-4 last:mr-0 text-xs lg:h-40 lg:w-40 lg:mr-7 cursor-pointer"
              icon={categoryIcon === "default" ? "image-outline" : undefined}
              freqIcon={categoryIcon !== "default" ? categoryIcon : undefined}
            >
              {category.categoryName}
            </Card>
          );
        })}
      </div>
    </div>
  );
};
