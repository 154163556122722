import React from "react";

export const WallsFloorsIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorSecond}
        d="M77.31368,35.82261,41,9.49339V50.57928L79.37854,78.37854,79.34346,39.797A4.91463,4.91463,0,0,0,77.31368,35.82261Z"
      />
      <path
        fill={colorFirst}
        d="M80.22168,77.16406l-5.61462-4.07092a1.48267,1.48267,0,0,0-.49616-.35974L42.5,49.81366V1.39062a1.5,1.5,0,1,0-3,0v48.423L7.89148,72.73169a1.48377,1.48377,0,0,0-.49878.36157l-5.61438,4.0708a1.48852,1.48852,0,0,0-.10352,2.34265c.02686.02344.05616.04181.08442.06305a1.4918,1.4918,0,0,0,.18762.12323c.03448.01868.0694.03449.1051.0503a1.49714,1.49714,0,0,0,.201.07251c.036.01013.07111.02044.10767.02789a1.50728,1.50728,0,0,0,.23224.02692c.02264.001.04461.00781.06726.00781l.0166-.00257a1.48774,1.48774,0,0,0,.39953-.06195c.02753-.008.05365-.01788.08088-.02746A1.48191,1.48191,0,0,0,3.54,79.59277l4.99017-3.61816,5.28815,3.8335a1.50019,1.50019,0,1,0,1.76172-2.42872L11.086,74.12152,19.35364,68.127l8.2677,5.99433L23.29,77.26172a1.50019,1.50019,0,0,0,1.76172,2.42871l5.12536-3.71606,5.28772,3.83374a1.50019,1.50019,0,1,0,1.76172-2.42872l-4.49371-3.258L41,68.12732l8.26715,5.994-4.49371,3.258a1.50019,1.50019,0,0,0,1.76172,2.42872l5.28772-3.83374,5.12536,3.71606A1.50019,1.50019,0,1,0,58.71,77.26172l-4.3313-3.14038,8.2677-5.99433L70.914,74.12152l-4.49408,3.25787a1.50019,1.50019,0,0,0,1.76172,2.42872l5.28815-3.8335L78.46,79.59277a1.50019,1.50019,0,1,0,1.76172-2.42871ZM41,52.43213l8.26782,5.99469L41,64.42133l-8.26782-5.99451ZM30.17712,72.26831,21.90936,66.274l8.26709-5.99414,8.26782,5.99444Zm21.64576,0-8.26715-5.994,8.26782-5.99444L60.09064,66.274Z"
      />
    </svg>
  );
};
