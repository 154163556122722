import classNames from "classnames";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { CategoryIcon } from "./CategoryIcon";

interface CardProps {
  children: ReactNode;
  className?: string;
  icon?: string;
  horizontal?: boolean;
  link?: string;
  onClick?: () => void;
  categoryIcon?: string;
  freqIcon?: string;
  selected?: boolean;
  smallIcons?: boolean;
}

export const Card = ({
  children,
  className,
  icon,
  horizontal,
  link,
  onClick,
  categoryIcon,
  freqIcon,
  selected,
  smallIcons,
}: CardProps) => {
  return (
    <Link
      className={classNames(
        "flex min-h-[105px] justify-center items-center bg-[#F6F8F6] rounded text-center text-sm text-[#2F231C] p-2",
        {
          "flex-col": !horizontal,
          "lg:flex-col": horizontal,
          "border border-[#6D8733]": selected,
        },
        className
      )}
      to={link ?? ""}
      onClick={onClick}
    >
      {!!icon && (
        <i
          data-eva={icon}
          data-eva-height={40}
          data-eva-width={40}
          data-eva-fill={"#6D8733"}
          className={classNames({
            "mb-4": !horizontal,
            "mr-4 lg:mb-4": horizontal,
          })}
        />
      )}

      {!!categoryIcon && (
        <div
          className={classNames("", {
            "mb-4": !horizontal,
            "mr-4 lg:mb-4 lg:mr-0": horizontal,
            "h-12 w-12 lg:h-16 lg:w-16": smallIcons,
            "h-20 w-20": !smallIcons,
          })}
        >
          <CategoryIcon categoryIcon={categoryIcon} />
        </div>
      )}

      {!!freqIcon && (
        <div
          className={classNames("h-10 w-10", {
            "mb-4": !horizontal,
            "mr-4 lg:mb-4 lg:mr-0": horizontal,
          })}
        >
          <CategoryIcon categoryIcon={freqIcon} />
        </div>
      )}
      {children}
    </Link>
  );
};
