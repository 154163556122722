import React from "react";

export const HomeAppliancesIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorSecond}
        d="M41.61658,35.69651a13.7548,13.7548,0,0,0-13.14717,6.16275,1.52572,1.52572,0,0,0-.04694,1.50381,13.71162,13.71162,0,0,1,0,12.02837,1.525,1.525,0,0,0,.04687,1.5037A13.771,13.771,0,1,0,41.61658,35.69651Z"
      />
      <rect
        fill={colorSecond}
        x="19.57394"
        y="6.91525"
        width="14.83992"
        height="8.16694"
        rx="1.05479"
      />
      <path
        fill={colorFirst}
        d="M65.19629.12109H14.80371a4.27434,4.27434,0,0,0-4.26953,4.26953V75.60938a4.27434,4.27434,0,0,0,4.26953,4.26953H65.19629a4.27434,4.27434,0,0,0,4.26953-4.26953V4.39062A4.27434,4.27434,0,0,0,65.19629.12109Zm-50.39258,3H65.19629a1.27075,1.27075,0,0,1,1.26953,1.26953V18.876H13.53418V4.39062A1.27075,1.27075,0,0,1,14.80371,3.12109ZM65.19629,76.87891H14.80371a1.27075,1.27075,0,0,1-1.26953-1.26953V21.876H66.46582v53.7334A1.27075,1.27075,0,0,1,65.19629,76.87891Z"
      />
      <path
        fill={colorSecond}
        d="M40,28.09277A21.28467,21.28467,0,1,0,61.28467,49.377,21.30846,21.30846,0,0,0,40,28.09277Zm0,39.56934A18.28467,18.28467,0,1,1,58.28467,49.377,18.306,18.306,0,0,1,40,67.66211Z"
      />
      <path
        fill={colorFirst}
        d="M41.01025,12.499H43.2959a1.5,1.5,0,0,0,0-3H41.01025a1.5,1.5,0,0,0,0,3Z"
      />
      <path
        fill={colorFirst}
        d="M49.5752,12.499h2.28564a1.5,1.5,0,0,0,0-3H49.5752a1.5,1.5,0,0,0,0,3Z"
      />
      <path
        fill={colorFirst}
        d="M58.14062,12.499h2.28565a1.5,1.5,0,0,0,0-3H58.14062a1.5,1.5,0,0,0,0,3Z"
      />
    </svg>
  );
};
