import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./styles/index.css";
import "./styles/fonts.css";
import "./styles/normalize.css";
import "./styles/tailwind.css";
import "./styles/intelo-bold.ttf";
import "./styles/intelo-regular.ttf";

import "./i18n";

import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Intelo",
          colorPrimary: "#6D8733",
          colorPrimaryActive: "#576B29",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
