import React from "react";

export const TilesIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon Final</title>
      <path
        fill={colorSecond}
        d="M74.47658,17.74209l-.01264.00738a1.32462,1.32462,0,0,1,.098.13147Z"
      />
      <path
        fill={colorSecond}
        d="M74.56348,19.38086a1.498,1.498,0,0,1-1.24854-.6665c-.01758-.022-.03955-.04932-.043-.0542a1.50015,1.50015,0,0,1,.44239-2.21,1.51318,1.51318,0,0,1,2.03906.50488l.07275.11816c.01221.01905.02344.03809.03467.05762a1.49992,1.49992,0,0,1-1.29736,2.25Z"
      />
      <path
        fill={colorSecond}
        d="M76.48659,21.02352l.00445-.00537-1.89453-3.08106a2.08809,2.08809,0,0,1-.10357,1.8385L57.112,57.04928a4.7355,4.7355,0,0,1-3.8518,2.62683H4.96443A1.746,1.746,0,0,1,3.71529,59.235l-.00092.00054L5.341,61.87667a1.343,1.343,0,0,0,.333.54065l.00311.00506.00214-.00122a1.75115,1.75115,0,0,0,1.23413.43012H55.20906a4.73545,4.73545,0,0,0,3.85181-2.62689l17.381-37.27369A2.01132,2.01132,0,0,0,76.48659,21.02352Z"
      />
      <path
        fill={colorSecond}
        d="M55.209,64.35107H6.91357a3.24869,3.24869,0,0,1-2.17919-.7622,1.49074,1.49074,0,0,1-.15284-.1416,2.81373,2.81373,0,0,1-.58984-.90088L2.437,60.022a1.50016,1.50016,0,0,1,2.31787-1.86718.98533.98533,0,0,0,.20948.02148H53.26025a3.253,3.253,0,0,0,2.49219-1.76074L73.13379,19.1416a.93977.93977,0,0,0,.12451-.52832,1.49953,1.49953,0,0,1,2.61621-1.46191L77.769,20.23242a1.4645,1.4645,0,0,1,.09131.17285,3.548,3.548,0,0,1-.05908,3.1792L60.42041,60.8584A6.19348,6.19348,0,0,1,55.209,64.35107ZM6.73975,61.33545a.87566.87566,0,0,0,.17382.01562H55.209a3.25446,3.25446,0,0,0,2.49268-1.76074L75.08252,22.31689a.92377.92377,0,0,0,.12207-.51757L58.47119,57.68311a6.192,6.192,0,0,1-5.21094,3.49316H6.66748A1.50344,1.50344,0,0,1,6.73975,61.33545Z"
      />
      <path
        fill={colorSecond}
        d="M12.25293,39.9126a1.5,1.5,0,0,1,0-3c14.23633,0,30.08594-9.15039,35.33105-20.39746a1.49986,1.49986,0,1,1,2.71875,1.26757C44.6123,29.98535,27.54346,39.9126,12.25293,39.9126Z"
      />
      <path
        fill={colorSecond}
        d="M17.03516,29.65674a1.5,1.5,0,0,1,0-3c7.11621,0,15.02685-4.54981,17.63476-10.1416a1.49986,1.49986,0,1,1,2.71875,1.26757C34.28418,24.44092,25.34375,29.65674,17.03516,29.65674Z"
      />
      <path
        fill={colorSecond}
        d="M65.80225,39.9126c-8.23731,0-14.80762-2.89795-18.02637-7.9502-2.75342-4.32275-2.82178-9.80859-.1919-15.44726a1.49986,1.49986,0,1,1,2.71875,1.26757c-2.18115,4.67725-2.17968,9.14063.00342,12.56788,2.65674,4.17041,8.30518,6.562,15.4961,6.562a1.5,1.5,0,0,1,0,3Z"
      />
      <path
        fill={colorSecond}
        d="M70.585,29.65674c-4.5166,0-8.14062-1.62207-9.94238-4.45069-1.563-2.45312-1.61475-5.54-.145-8.69091a1.49986,1.49986,0,0,1,2.71875,1.26757c-1.03565,2.2212-1.05078,4.23047-.04346,5.81153,1.23975,1.94629,3.94141,3.0625,7.41211,3.0625a1.5,1.5,0,0,1,0,3Z"
      />
      <path
        fill={colorSecond}
        d="M29.11133,61.17676a1.50109,1.50109,0,0,1-1.3584-2.13428C33.44287,46.83984,50.51172,36.9126,65.80225,36.9126a1.5,1.5,0,0,1,0,3c-14.23682,0-30.08594,9.15039-35.33057,20.39746A1.49994,1.49994,0,0,1,29.11133,61.17676Z"
      />
      <path
        fill={colorSecond}
        d="M42.0249,61.17676a1.50109,1.50109,0,0,1-1.3584-2.13428C43.77148,52.38379,52.71191,47.168,61.02,47.168a1.5,1.5,0,0,1,0,3c-7.11621,0-15.02686,4.5498-17.63477,10.14209A1.49994,1.49994,0,0,1,42.0249,61.17676Z"
      />
      <path
        fill={colorSecond}
        d="M29.11133,61.17676a1.50109,1.50109,0,0,1-1.3584-2.13428c2.18066-4.67725,2.1792-9.14062-.00391-12.56787-2.65673-4.17041-8.30517-6.562-15.49609-6.562a1.5,1.5,0,0,1,0-3c8.2373,0,14.80713,2.898,18.02637,7.95019,2.75341,4.32227,2.82177,9.8086.19238,15.44727A1.49994,1.49994,0,0,1,29.11133,61.17676Z"
      />
      <path
        fill={colorSecond}
        d="M16.19775,61.17676a1.50109,1.50109,0,0,1-1.35839-2.13428c1.03564-2.22119,1.05078-4.231.04345-5.812-1.23974-1.94629-3.9414-3.0625-7.41211-3.0625a1.5,1.5,0,0,1,0-3c4.5166,0,8.14014,1.62207,9.94239,4.45068,1.563,2.45362,1.61474,5.54053.145,8.69141A1.5,1.5,0,0,1,16.19775,61.17676Z"
      />
      <path
        fill={colorFirst}
        d="M53.26025,61.17627H4.96436a3.11419,3.11419,0,0,1-2.71143-1.37354,3.486,3.486,0,0,1-.04981-3.3872L19.584,19.1416a6.1935,6.1935,0,0,1,5.21143-3.49267H73.09082a3.11542,3.11542,0,0,1,2.71191,1.374,3.484,3.484,0,0,1,.04981,3.38623L58.47119,57.68311A6.192,6.192,0,0,1,53.26025,61.17627ZM24.79541,18.64893a3.25449,3.25449,0,0,0-2.49268,1.76074L4.92188,57.68311a1.13936,1.13936,0,0,0-.13135.49365.83736.83736,0,0,1,.17383-.00049H53.26025a3.253,3.253,0,0,0,2.49219-1.76074L73.13379,19.1416a1.14574,1.14574,0,0,0,.13135-.49316.803.803,0,0,1-.17432.00049Z"
      />
      <path
        fill={colorSecond}
        d="M37.15576,43.9292a1.49876,1.49876,0,0,1-1.17627-.56787l-3.18506-4.0166a1.50009,1.50009,0,0,1,.42334-2.23l6.93116-4.01709a1.4994,1.4994,0,0,1,1.92724.36621L45.26074,37.481a1.49965,1.49965,0,0,1-.42334,2.22949l-6.93066,4.0166A1.49694,1.49694,0,0,1,37.15576,43.9292Zm-.93262-5.08887,1.30127,1.64112L41.832,37.98486l-1.30078-1.64111Z"
      />
    </svg>
  );
};
