import React from "react";

export const DamageIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorFirst}
        d="M64.771,11.36035H57.23535V7.21387a1.49976,1.49976,0,0,0-1.5-1.5H48.94287a9.94661,9.94661,0,0,0-17.88574,0H24.26465a1.49976,1.49976,0,0,0-1.5,1.5v4.14648H15.229a1.49977,1.49977,0,0,0-1.5,1.5V78.37842a1.49977,1.49977,0,0,0,1.5,1.5H64.771a1.49977,1.49977,0,0,0,1.5-1.5V12.86035A1.49977,1.49977,0,0,0,64.771,11.36035ZM25.76465,8.71387h6.28418A1.50067,1.50067,0,0,0,33.46,7.72314a6.94835,6.94835,0,0,1,13.08008,0,1.50067,1.50067,0,0,0,1.41113.99073h6.28418V17.603H25.76465ZM63.271,76.87842H16.729V14.36035h6.03565V19.103a1.49977,1.49977,0,0,0,1.5,1.5h31.4707a1.49977,1.49977,0,0,0,1.5-1.5V14.36035H63.271Z"
      />
      <path
        fill={colorSecond}
        d="M54.061,39.1601l-4.65717-2.50123L41.43866,47.31415h4.37622a.59438.59438,0,0,1,.39319,1.0401L33.45569,59.60382a.64757.64757,0,0,1-.99774-.79413l4.58661-8.46521H33.74164a.80179.80179,0,0,1-.679-1.22815l9.96985-15.87939-2.3443-1.259a1.45459,1.45459,0,0,0-1.37634,0L25.939,39.1601a1.45428,1.45428,0,0,0-.76629,1.28131V61.76215a1.45444,1.45444,0,0,0,1.45441,1.45446H53.37292a1.45444,1.45444,0,0,0,1.45441-1.45446V40.44141A1.45428,1.45428,0,0,0,54.061,39.1601Z"
      />
    </svg>
  );
};
