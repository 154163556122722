import React from "react";

export const KeyLostIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <circle fill={colorSecond} cx="17.73353" cy="16.31447" r="4.7949" />
      <path
        fill={colorFirst}
        d="M68.02148,66.23242a1.49984,1.49984,0,1,0,2.1211-2.12109L51.53223,45.501a1.49984,1.49984,0,0,0-2.1211,2.12109Z"
      />
      <path
        fill={colorFirst}
        d="M79.43945,9.60547a1.49944,1.49944,0,0,0-2.12109,0L51.18549,35.73834l-4.4961-4.49621a22.677,22.677,0,0,0-1.499-18.38459c-.01917-.03527-.03564-.07183-.05505-.10705-.17017-.30774-.35621-.60791-.54151-.90906-.144-.23547-.28918-.47-.44226-.70081q-.2449-.36749-.50525-.7265c-.21545-.29895-.44122-.59137-.6723-.88116-.14587-.18231-.28741-.36646-.43958-.54517-.40075-.472-.81683-.9342-1.26007-1.37744A22.80074,22.80074,0,1,0,30.35938,45.918l2.07031,2.07031a1.49984,1.49984,0,0,0,2.12109-2.12109L31.877,43.19336a1.50518,1.50518,0,0,0-1.458-.38574A19.79445,19.79445,0,1,1,39.15332,9.73145c.39014.39007.75525.79608,1.10681,1.20977.13971.16486.26959.33582.40326.5044.19544.24573.387.49353.56934.74621q.243.33839.46991.68549c.12494.18939.24444.38122.3623.574.17224.28315.34277.56708.49994.85743.05548.102.10522.20629.15887.30908A19.81849,19.81849,0,0,1,41.291,35.21a1.50044,1.50044,0,0,0,2.44336,1.74219,22.98313,22.98313,0,0,0,1.66406-2.75781L49.064,37.85986,9.60547,77.31836a1.49984,1.49984,0,1,0,2.12109,2.12109L36.84924,54.31677l.63318,5.07971a1.5,1.5,0,0,0,1.30274,1.30274l6.99707.87109.874,7A1.499,1.499,0,0,0,47.958,69.87305l7.00488.87695.877,7.00488a1.49958,1.49958,0,0,0,1.48828,1.31348h.01563L76.165,78.87012a1.50056,1.50056,0,0,0,1.48437-1.52637l-.25879-14.80469a1.49683,1.49683,0,0,0-.43945-1.03418L53.30652,37.8595,79.43945,11.72656A1.49944,1.49944,0,0,0,79.43945,9.60547Zm-5.03808,53.5918.22168,12.68847-15.97266.16895-.85547-6.835A1.49894,1.49894,0,0,0,56.49316,67.918L49.48828,67.041l-.874-7.001a1.5,1.5,0,0,0-1.30274-1.30274l-6.99707-.87109L39.537,51.629,51.18506,39.981Z"
      />
    </svg>
  );
};
