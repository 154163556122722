import { Input } from "antd";
import { TextAreaProps } from "antd/es/input/TextArea";
import classNames from "classnames";

const { TextArea: AtndTextArea } = Input;

export const TextArea = (props: TextAreaProps) => {
  return (
    <AtndTextArea
      className={classNames(
        "bg-[#F6F8F6] placeholder-[#626360] rounded border-none hover:border-none focus:border-none pt-4",
        props.className
      )}
      {...props}
    />
  );
};
