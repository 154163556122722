import { ReactNode } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as eva from "eva-icons";
import classNames from "classnames";
import { Dropdown } from "antd";
import { languages } from "../utils/shared/languages";
import { useTranslation } from "react-i18next";
import { Steps } from "./Steps";
import React from "react";
import { useQuestionsActionsContext } from "../pages/Questions/providers/QuestionsProvider";

interface ContainerProps {
  children: ReactNode;
  padding?: boolean;
  reportStep?: number;
  bottomButtons?: ReactNode;
  steps?: { label: string; step: number }[];
}

const languagesMenu = [
  {
    key: languages.en,
    label: "Eng",
  },
  {
    key: languages.de,
    label: "De",
  },
  {
    key: languages.fr,
    label: "Fr",
  },
  {
    key: languages.it,
    label: "It",
  },
];

export const Container = ({
  children,
  padding = true,
  reportStep,
  bottomButtons,
  steps,
}: ContainerProps) => {
  const { accountName } = useParams();
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const { setInitialQuestionsState } = useQuestionsActionsContext();

  useEffect(() => {
    eva.replace();
  }, [children]);

  const onLogoClick = () => {
    setInitialQuestionsState();
    navigate(`/${accountName}`);
  };

  return (
    <div
      className={classNames("h-screen grid grid-rows-[64px_1fr_min-content]", {
        "lg:grid-rows-[64px_64px_1fr_min-content]": bottomButtons && reportStep,
        "lg:grid-rows-[64px_64px_1fr]": !bottomButtons && reportStep,
        "lg:grid-rows-[64px_1fr]": !bottomButtons && !reportStep,
      })}
    >
      <div className="flex justify-center w-full border-[#ECEDEB] border-b sticky top-0 bg-white z-10">
        <div className="h-16 flex justify-between items-center px-4 max-w-7xl w-full relative xl:px-0">
          <div className="text-[#2F231C] font-semibold w-20">
            <Dropdown
              menu={{
                items: languagesMenu.map((elem) => ({
                  ...elem,
                  onClick: () => i18n.changeLanguage(elem.key),
                })),
              }}
            >
              <div className="flex">
                <span className="text-[#2F231C]">
                  {
                    languagesMenu.find((elem) => elem.key === i18n.language)
                      ?.label
                  }
                </span>
                <i
                  data-eva="arrow-ios-downward-outline"
                  data-eva-fill="#626360"
                />
              </div>
            </Dropdown>
          </div>
          {!!accountName && (
            <img
              className="h-6 absolute left-[calc(50%-60px)] cursor-pointer"
              src={`https://gw-damagereportform-pool.enable.jarowa.ch/DamageReportForm/api/v1/Accounts/${accountName}/Logo`}
              alt="account logo"
              onClick={onLogoClick}
            />
          )}
          <div />
        </div>
      </div>

      {reportStep && (
        <div className="bg-white w-full border-[#ECEDEB] border-b hidden justify-center lg:flex">
          <Steps
            activeStep={reportStep}
            className="max-w-7xl pt-4"
            steps={steps}
          />
        </div>
      )}

      <div
        className={classNames(
          "h-full overflow-y-auto flex justify-center w-full",
          {
            "py-8 px-4": padding,
          }
        )}
      >
        <div className="max-w-7xl w-full">{children}</div>
      </div>

      {(reportStep || bottomButtons) && (
        <div
          className={classNames(
            "border-[#ECEDEB] border-t bg-white w-full p-4 flex justify-center items-center flex-col",
            {
              "lg:hidden": !bottomButtons,
            }
          )}
        >
          {reportStep && (
            <Steps
              activeStep={reportStep}
              className={classNames("max-w-7xl lg:hidden", {
                "mb-4": bottomButtons,
              })}
              steps={steps}
            />
          )}
          {bottomButtons && (
            <div className="max-w-7xl w-full">{bottomButtons}</div>
          )}
        </div>
      )}
    </div>
  );
};
