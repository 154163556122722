import { RcFile } from "antd/es/upload";

interface FileType {
  fileName: string;
  fileContent: string;
  fileType: string;
}

export const getFileBase64 = (file: RcFile): Promise<FileType> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      return resolve({
        fileName: file.name,
        fileContent: result.substring(result.indexOf(",") + 1),
        fileType: "photo",
      });
    };
    reader.onerror = (error) => reject(error);
  });
