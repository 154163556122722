import { InputNumber as AntdInput } from "antd";
import type { InputNumberProps } from "antd";
import classNames from "classnames";
import { forwardRef } from "react";

interface Props {
  isError?: boolean;
}

export const InputNumber = forwardRef<
  HTMLInputElement | null,
  Props & InputNumberProps
>((props, ref) => {
  return (
    <AntdInput
      {...props}
      className={classNames(
        "bg-[#F6F8F6] h-14 placeholder-[#626360] rounded",
        {
          "border-none hover:border-none": !props.isError,
          "border border-[#FF5747] focus:border-[#FF5747] hover:border-[#FF5747]":
            props.isError,
        },
        props.className
      )}
      ref={ref}
      controls={false}
    />
  );
});
