import React from "react";
import { useReportDamageDataContext } from "../pages/Report/providers/ReportDamageProvider";
import {
  BillsIcon,
  ClimateIcon,
  CommonSpaceIcon,
  DamageIcon,
  DoorsWindowsIcon,
  ElectricityIcon,
  GeneralQuestionIcon,
  HomeAppliancesIcon,
  ImageDetectionIcon,
  IndoorIcon,
  KeyLostIcon,
  OutdoorIcon,
  QuestionIcon,
  SanitaryIcon,
  SubmitSuccessIcon,
  WallsFloorsIcon,
  PaintingIcon,
  TilesIcon,
  WoodIcon,
} from "../icons";

const getIcon = (categoryIcon: string) => {
  switch (categoryIcon) {
    case "bills":
      return BillsIcon;
    case "climate":
      return ClimateIcon;
    case "common_space":
      return CommonSpaceIcon;
    case "damage":
      return DamageIcon;
    case "doors_windows":
      return DoorsWindowsIcon;
    case "electricity":
      return ElectricityIcon;
    case "general_question":
      return GeneralQuestionIcon;
    case "home_appliances":
      return HomeAppliancesIcon;
    case "image_detection":
      return ImageDetectionIcon;
    case "indoor":
      return IndoorIcon;
    case "key_lost":
      return KeyLostIcon;
    case "outdoor":
      return OutdoorIcon;
    case "question":
      return QuestionIcon;
    case "sanitary":
      return SanitaryIcon;
    case "submit_success":
      return SubmitSuccessIcon;
    case "walls_floors":
      return WallsFloorsIcon;
    case "painting":
      return PaintingIcon;
    case "tiles":
      return TilesIcon;
    case "wood":
      return WoodIcon;
    default:
      return null;
  }
};

export const CategoryIcon = ({ categoryIcon }: { categoryIcon: string }) => {
  const {
    account: { colorFirst, colorSecond, accountName },
  } = useReportDamageDataContext();

  const Icon = getIcon(categoryIcon);

  return accountName ? (
    <Icon colorFirst={colorFirst} colorSecond={colorSecond} />
  ) : null;
};
