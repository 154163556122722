import React from "react";

export const IndoorIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorSecond}
        d="M17.82311,48.79094H34.69943a1.24281,1.24281,0,0,1,1.24281,1.24281v28.3448a0,0,0,0,1,0,0H16.5803a0,0,0,0,1,0,0V50.03375A1.24281,1.24281,0,0,1,17.82311,48.79094Z"
      />
      <rect
        fill={colorSecond}
        x="41.52134"
        y="48.79094"
        width="21.89836"
        height="16.50529"
        rx="1.24281"
      />
      <path
        fill={colorFirst}
        d="M71.09473,16.61914,40.71.30029a1.49958,1.49958,0,0,0-1.41992,0L25.67773,7.61108V1.62158a1.50007,1.50007,0,0,0-1.5-1.5H9.61523a1.50008,1.50008,0,0,0-1.5,1.5V78.37842a1.50008,1.50008,0,0,0,1.5,1.5H70.38477a1.50008,1.50008,0,0,0,1.5-1.5v-60.438A1.50017,1.50017,0,0,0,71.09473,16.61914ZM22.67773,3.12158V9.22229l-11.5625,6.21V3.12158Zm46.207,73.75684H11.11523V18.8374L40,3.32422,68.88477,18.8374Z"
      />
      <path
        fill={colorFirst}
        d="M30.33008,26.11035A9.66992,9.66992,0,1,0,40,16.44043,9.681,9.681,0,0,0,30.33008,26.11035Zm16.33984,0A6.66992,6.66992,0,1,1,40,19.44043,6.67768,6.67768,0,0,1,46.66992,26.11035Z"
      />
    </svg>
  );
};
