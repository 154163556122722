import React from "react";

export const WoodIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon Final</title>
      <path
        fill={colorSecond}
        d="M78.37842,40.86035H53.96924a6.48038,6.48038,0,0,1-4.14356-1.50146l-3.77734-3.15235a6.46719,6.46719,0,0,1,2.22559-11.1416l2.456-.76269a6.46876,6.46876,0,0,1,1.918-.29053H57.6582a1.5,1.5,0,0,1,0,3H52.648a3.46693,3.46693,0,0,0-1.02881.15576l-2.45557.7627a3.467,3.467,0,0,0-1.19336,5.97265l3.77735,3.15235a3.47438,3.47438,0,0,0,2.22168.80517H78.37842a1.5,1.5,0,0,1,0,3Z"
      />
      <path
        fill={colorSecond}
        d="M33.57178,58.60938H1.62158a1.5,1.5,0,0,1,0-3h31.9502a3.37609,3.37609,0,0,0,3.37256-3.37256V49.19727a1.5,1.5,0,0,1,3,0v3.03955A6.38,6.38,0,0,1,33.57178,58.60938Z"
      />
      <path
        fill={colorSecond}
        d="M27.44043,49.70459H21.64355a6.37256,6.37256,0,0,1,0-12.74512H33.57178A6.38,6.38,0,0,1,39.94434,43.332a1.5,1.5,0,0,1-3,0,3.37609,3.37609,0,0,0-3.37256-3.37256H21.64355a3.37256,3.37256,0,0,0,0,6.74512h5.79688a1.5,1.5,0,0,1,0,3Z"
      />
      <path
        fill={colorSecond}
        d="M51.57764,58.60938H49.45312a1.5,1.5,0,0,1,0-3h2.12452a4.29392,4.29392,0,0,0,3.42773-1.72071l2.89893-3.89013a7.30529,7.30529,0,0,1,5.833-2.92823h6.68018a1.5,1.5,0,0,1,0,3H63.7373a4.29182,4.29182,0,0,0-3.42724,1.72022l-2.89942,3.89062A7.30669,7.30669,0,0,1,51.57764,58.60938Z"
      />
      <path
        fill={colorSecond}
        d="M15.62744,29.96094H1.62158a1.5,1.5,0,0,1,0-3H15.62744a1.5,1.5,0,0,1,0,3Z"
      />
      <path
        fill={colorSecond}
        d="M26.27393,29.96094H22.21387a1.5,1.5,0,0,1,0-3h4.06006a2.5748,2.5748,0,0,0,1.83154-.7583l3.50293-3.50293a5.55158,5.55158,0,0,1,3.95215-1.6377H40.96a1.5,1.5,0,0,1,0,3H35.56055a2.573,2.573,0,0,0-1.83106.75879l-3.50293,3.50293A5.55346,5.55346,0,0,1,26.27393,29.96094Z"
      />
      <path
        fill={colorFirst}
        d="M75.353,68.03125H4.647A4.53087,4.53087,0,0,1,.12158,63.50537V16.49463A4.53087,4.53087,0,0,1,4.647,11.96875H75.353a4.53087,4.53087,0,0,1,4.52539,4.52588V63.50537A4.53087,4.53087,0,0,1,75.353,68.03125ZM4.647,14.96875a1.52727,1.52727,0,0,0-1.52539,1.52588V63.50537A1.52727,1.52727,0,0,0,4.647,65.03125H75.353a1.52727,1.52727,0,0,0,1.52539-1.52588V16.49463A1.52727,1.52727,0,0,0,75.353,14.96875Z"
      />
      <path
        fill={colorSecond}
        d="M64.07617,33.936H62.04932a1.5,1.5,0,0,1,0-3h2.02685a1.96216,1.96216,0,1,0,0-3.92432,1.5,1.5,0,0,1,0-3,4.96216,4.96216,0,1,1,0,9.92432Z"
      />
    </svg>
  );
};
