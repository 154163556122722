import { Button as AntdButton } from "antd";
import type { ButtonProps } from "antd";
import classNames from "classnames";

export const Button = (props: ButtonProps) => {
  return (
    <AntdButton
      {...props}
      className={classNames("!rounded shadow-none", props.className)}
    />
  );
};
