import { Card } from "../components/Card";
import { Container } from "../components/Container";
import { useTranslation } from "react-i18next";
import { useReportQueryParams } from "./Report/hooks/useReportQueryParams";
import { FrequentlyUsed } from "./Report/components/FrequentlyUsed";
import React from "react";

export const HomePage = () => {
  const { t } = useTranslation();

  useReportQueryParams();

  return (
    <Container>
      <div className="text-2xl font-semibold text-[#2F231C] sm:text-center lg:text-4xl">
        {t("welcome")}
      </div>
      <div className="text-[#626360] mt-1 sm:text-center">
        {t("how_might_we_help_you")}
      </div>

      <div className="grid gap-4 grid-cols-[1fr_1fr] grid-rows-[164px_88px] mt-4 lg:grid-cols-[1fr_1fr_1fr] lg:grid-rows-[240px] lg:mt-10">
        <Card categoryIcon="damage" link="report">
          {t("report_damage_manually")}
        </Card>

        <Card categoryIcon="question" link="questions">
          {t("administrative_questions")}
        </Card>

        <Card
          categoryIcon="image_detection"
          className="col-span-2 lg:col-auto"
          horizontal
          link="report/camera"
        >
          {t("determine_the_type_of_damage_by_photo")}
        </Card>
      </div>
      <FrequentlyUsed redirect />
    </Container>
  );
};
