import React from "react";

export const QuestionIcon = ({
  colorFirst,
  colorSecond,
}: {
  colorFirst: string;
  colorSecond: string;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <title>Icon</title>
      <path
        fill={colorFirst}
        d="M66.36505,17.00055l-.00055-.00543a1.49271,1.49271,0,0,0-.12921-.36292c-.01459-.02984-.02991-.05816-.04651-.087a1.491,1.491,0,0,0-.23468-.31714L50.28711.561a1.489,1.489,0,0,0-.31482-.23353C49.942.31006,49.91229.294,49.881.27875A1.49361,1.49361,0,0,0,49.5296.15344L49.50616.14929a1.49834,1.49834,0,0,0-.2796-.02771H15.10645a1.49976,1.49976,0,0,0-1.5,1.5V78.37842a1.49976,1.49976,0,0,0,1.5,1.5h49.7871a1.49976,1.49976,0,0,0,1.5-1.5V17.28857A1.50046,1.50046,0,0,0,66.36505,17.00055Zm-5.09259-1.212H50.72656V5.24268Zm-44.666,61.08985V3.12158H47.72656v14.167a1.49976,1.49976,0,0,0,1.5,1.5h14.167V76.87842Z"
      />
      <path
        fill={colorSecond}
        d="M40,25.68164A18.97946,18.97946,0,1,0,58.97949,44.66113,18.97949,18.97949,0,0,0,40,25.68164Zm2.455,29.13879a1.66974,1.66974,0,0,1-1.6698,1.66974H39.37378A1.66973,1.66973,0,0,1,37.704,54.82043V53.57135a1.66978,1.66978,0,0,1,1.66974-1.6698h1.41144a1.66979,1.66979,0,0,1,1.6698,1.6698ZM44.1145,45.7511A3.6454,3.6454,0,0,0,42.16156,48.868a.91717.91717,0,0,1-.906.85309h-2.498a.91158.91158,0,0,1-.90985-.93909c.08551-2.48346.62982-3.77618,3.3056-5.66668a3.89249,3.89249,0,0,0,2.01758-3.22174,2.93408,2.93408,0,0,0-3.15662-3.22174A3.4066,3.4066,0,0,0,36.57,39.76013a.82948.82948,0,0,1-.82007.68653H32.78607a.84432.84432,0,0,1-.8352-.9206c.43774-4.955,4.423-6.694,7.77075-6.694,1.95251,0,8.33087.553,8.33087,6.9314C48.05249,42.39954,46.62042,43.96136,44.1145,45.7511Z"
      />
    </svg>
  );
};
