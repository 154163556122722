import { createContext, ReactNode, useContext, useState } from "react";
import { languages } from "../utils/shared/languages";

interface UserDataTypes {
  language: string;
  setLanguage: (val: string) => void;
}

const initialData: UserDataTypes = {
  language: languages.en,
  setLanguage: () => Promise.resolve(),
};

const UserDataContext = createContext<UserDataTypes>(initialData);

interface ProviderProps {
  children: ReactNode;
}

export const UserDataProvider = ({ children }: ProviderProps) => {
  const [language, setLanguage] = useState(languages.en);

  return (
    <UserDataContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => useContext(UserDataContext);
